<template>
  <div class="tool-wrap">
    <el-button
      v-if="internetEdit"
      type="primary"
      style="width: 100px; height: 40px; margin-right: 10px"
      @click="editFn"
      >{{ $t("btn.editBtn") }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    internetEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    editFn() {
      this.$emit("editFn");
    },
  },
};
</script>
<style scoped lang="scss">
.tool-wrap {
  text-align: right;
  padding-right: 86px;
  height: 50px;
  line-height: 50px;
  position: relative;
}
</style>

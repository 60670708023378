<template>
  <div>
    <img id="drawio-container" :src="src" style="display: none" />
    <img src="@/assets/img/drawio.png" class="svgimg1" @click="add_draw" />
  </div>
</template>

<script>
import { Drawio } from "@/plugins/drawio.js";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
import { getToken } from "@/utils/auth";
export default {
  props: {
    quill: {
      type: Object,
      default() {
        return null;
      },
    },
    imgDom: {
      type: HTMLImageElement,
      default() {
        return null;
      },
    },
    client: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      src: "data:image/png;",
    };
  },

  async created() {},
  destroyed() {
    window.removeEventListener("message", this.getMessage);
  },
  mounted() {
    window.addEventListener("message", this.getMessage);
  },
  methods: {
    getMessage(e) {
      try {
        if (typeof e.data == "string") {
          let data = JSON.parse(e.data);
          if (data.event === "export") {
            this.save_draw_change(data.data);
          }
        }
      } catch (error) {}
    },
    // base64转file
    dataURLtoFile(dataurl, filename) {
      if (!dataurl) return;
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    save_draw_change(data) {
      const self = this;
      if (this.imgDom) {
        const file = this.dataURLtoFile(data, ulid());
        this.client
          .put(`/comment/${new Date().getTime()}${file.name}`, file)
          .then((res) => {
            self.imgDom.src = res.url;
          })
          .catch((err) => {
            self.imgDom.src = null;
          });
        this.$nextTick(() => {
          this.quill.getModule("ImageResize").hide();
        });
      } else {
        const index = this.quill.getSelection()
          ? this.quill.getSelection().index
          : this.quill.getLength();
        this.quill.insertEmbed(index, "image", {
          src: data,
          drawio: true,
          width: this.imgDom ? this.imgDom.width : "300",
        });
      }
    },
    edit_draw() {
      const container = document.getElementById("drawio-container");
      Drawio.editElement(container);
    },
    add_draw(src) {
      if (typeof src === "string") {
        this.src = src;
      } else {
        this.src = "data:image/png;";
        document
          .getElementById("drawio-container")
          .setAttribute("src", this.src);
        this.$emit("clearImgDom");
      }
      this.$nextTick(() => {
        this.edit_draw();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.svgimg1 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>

import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
export function aiRobotCreat(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/aiRobot/file`,
        method: 'post',
        data: params.data
    })
}
// ai机器人付费查询
export function aiRobotPPayFeatureStatus(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/aiRobot/payFeatureStatus`,
        method: 'get'
    })
}
// ai补充付费查询
export function aiAutoExtendPayFeatureStatus(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/aiAutoExtend/payFeatureStatus`,
        timeout: 300000,
        method: 'get'
    })
}

export function given_content_pic(projectId, data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/aiRobot/generateImage`,
        method: 'post',
        data: data
    })
}
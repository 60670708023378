<template>
  <div class="knowledge_wrap">
    <el-header style="height: 60px">
      <div class="left-header">
        <img class="topbar-logo" src="@/assets/logo.png" />
        <div class="head-header">
          <img class="name_logo" src="@/assets/name_logo.png" alt="" />
        </div>
      </div>
      <language class="language-option"></language>
    </el-header>
    <div v-if="!is_edit && valid" class="not_edit_class">
      <div class="titleContainer">
        <Title
          :document-info="documentInfo"
          :internet-edit="internetEdit"
          @editFn="editFn"
        ></Title>
        <!-- <Tool :internet-edit="internetEdit" @editFn="editFn"></Tool> -->
      </div>
      <div id="not-edit-wrap" class="not-edit-wrap">
        <Catalogue
          :content="content"
          @catalogueUpdate="catalogueUpdate"
          :editable="false"
        ></Catalogue>
        <Document
          :content="content"
          :internet-token="internetForm.token"
        ></Document>
      </div>
    </div>
    <div v-else-if="is_edit && valid" style="height: calc(100% - 40px)">
      <edit-top
        :user-list-now="userListNow"
        :internet-token="internetForm.token"
        :document-info="documentInfo"
        :sending="sending"
        @notEditFn="notEditFn"
      ></edit-top>
      <div class="knowledge-edit-main">
        <Catalogue
          ref="catalogue"
          :content="content"
          :editable="true"
        ></Catalogue>
        <edit-wrap
          :document-info="documentInfo"
          :content="content"
          :internet-token="internetForm.token"
          @contenthange="contentChange"
          @userlistchange="userlistchange"
          @sendingFn="sendingFn"
        ></edit-wrap>
      </div>
    </div>
    <el-dialog
      :visible.sync="tokendialog"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">{{ $t("projectDetails.EnterToken") }}</div>
      <div style="text-align: left">
        <el-form
          ref="authorityForm"
          :model="internetForm"
          label-width="120px"
          label-position="left"
        >
          <el-form-item prop="only" label="token">
            <el-input v-model="internetForm.token"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tokenConfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Title from "./components/notEdit/title.vue";
import Tool from "./components/notEdit/tool.vue";
import Catalogue from "./components/notEdit/catalogue.vue";
import Document from "./components/notEdit/document.vue";
import EditTop from "./components/edit/editTop.vue";
import EditWrap from "./components/edit/editWrap.vue";
import {
  getDocumentUnlogin,
  linkValidInter,
  linkTokenValid,
  linkValidQuick,
  getContentUnlogin,
  internetOrquick,
  pubExitEdit,
} from "@/network/knowledge";
import { customHeader, stopInterval } from "@/plugins/quill.custom.js";
import { sessionSave, sessionRemove } from "@/utils/session";
import { mapGetters } from "vuex";
import moment from "moment";
import language from "@/components/language";
export default {
  components: {
    Title,
    Tool,
    Catalogue,
    Document,
    EditTop,
    EditWrap,
    language,
  },
  data() {
    return {
      is_edit: false,
      valid: false,
      documentInfo: {},
      loading: null,
      content: "",
      userListNow: [],
      authorObj: {},
      tokendialog: false,
      internetForm: {
        token: "",
      },
      internetEdit: false,
      // 上次定位的id
      lastHash: "",
      // 上次编辑时间
      updatedAt: "",
      sending: false,
      // 进入事件
      enterTime: "",
      trackingList: ["accffe88-d8ef-458d-80e6-5ea2ab55de07"],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  watch: {
    // $route (val) {
    //   console.log("val.hash", val.hash)
    //   if (val.hash) {
    //     this.locationElement(val.hash);
    //   }
    // },
    valid(newval) {
      if (newval) {
        this.getDocument();
        this.getContent();
      }
    },
  },
  mounted() {
    this.linkValid();
    // this.locationElement();
    customHeader();
    this.enterTime = new Date().getTime();
    if (this.trackingList.indexOf(this.$route.params.pathId) !== -1) {
      window.addEventListener(
        "beforeunload",
        async (e) => await this.beforeunloadHandler(e)
      );
    }
  },
  beforeDestroy() {
    stopInterval();
    sessionRemove("internetToken");
    sessionRemove("pathId");
    if (this.trackingList.indexOf(this.$route.params.pathId) !== -1) {
      window.removeEventListener("beforeunload", (e) =>
        this.beforeunloadHandler(e)
      );
    }
  },
  methods: {
    // 后台埋点
    async beforeunloadHandler(e) {
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
      const duration = Math.floor(
        (new Date().getTime() - this.enterTime) / 1000
      );
      const email = this.user_info ? this.user_info.email : "";
      const accountId = this.user_info ? this.user_info.accountId : "";
      const tenantId = this.user_info ? this.user_info.tenantId : "";
      const blob = new Blob(
        [JSON.stringify({ email, accountId, tenantId, timestamp, duration })],
        {
          type: "application/json; charset=UTF-8",
        }
      );
      navigator.sendBeacon(
        `${process.env.VUE_APP_BASE_API}/file-manage-service/publicResource/eventTracking/recommendBenefits`,
        blob
      );
    },
    creatLoading() {
      this.loading = this.$loading({
        target: "#knowledge-home-wrap",
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    // 定位参数dom
    // locationElement (hash) {
    //   console.log("?????????????????", this.lastHash)
    //   if ((this.$route.query && this.$route.query.elementId) || hash) {
    //     this.lastHash
    //       ? document
    //         .getElementById(this.lastHash)
    //         .classList.remove("position_dom")
    //       : "";
    //     const id = hash ? hash.split("#")[1] : this.$route.query.elementId;
    //     const dom = document.getElementById(id);
    //     if (dom) {
    //       dom.classList.add("position_dom");
    //     }
    //     window.location.href = "#" + id;
    //     this.lastHash = id;
    //     console.log("this.lastHash", this.lastHash);
    //   }
    // },
    // 获取文档信息
    getDocument() {
      const params = {
        pathId: this.$route.params.pathId,
      };
      getDocumentUnlogin(params).then((res) => {
        this.documentInfo = res;
      });
    },
    getContent() {
      this.creatLoading();
      const params = {
        pathId: this.$route.params.pathId,
      };
      getContentUnlogin(params).then((res) => {
        if (res.content) {
          this.content = res.content;
          this.updatedAt = res.updatedAt;
        } else {
          this.content = "";
          this.updatedAt = res.updatedAt;
        }
        this.loading.close();
      });
    },
    // 确定输入互联网token
    tokenConfirm() {
      const params = {
        pathId: this.$route.params.pathId,
        query: {
          token: this.internetForm.token,
        },
      };
      linkTokenValid(params)
        .then((res) => {
          this.valid = true;
          this.tokendialog = false;
          this.is_edit = true;
          sessionSave("internetToken", this.internetForm.token);
          sessionSave("pathId", this.$route.params.pathId);
        })
        .catch((err) => {
          this.valid = false;
        });
    },
    async linkValid() {
      const res = await internetOrquick({
        pathId: this.$route.params.pathId,
      });
      if (res.shareType === "INTERNET_SHARE") {
        this.internetEdit = res.internetEdit;
        const params = {
          pathId: this.$route.params.pathId,
        };
        linkValidInter(params)
          .then((res) => {
            this.valid = true;
          })
          .catch((err) => {
            this.valid = false;
          });
      } else if (res.shareType === "QUICK_SHARE") {
        const params = {
          pathId: this.$route.params.pathId,
        };
        linkValidQuick(params).then((res) => {
          this.valid = true;
        });
      } else {
        this.valid = false;
      }
    },
    editFn() {
      if (this.internetEdit) {
        this.tokendialog = true;
      }
    },
    notEditFn() {
      this.is_edit = false;
      const oldUpdatedAt = this.updatedAt;
      const params = {
        pathId: this.$route.params.pathId,
      };
      getContentUnlogin(params).then((res) => {
        let isUpdate = false;
        if (res.content) {
          this.content = res.content;
        } else {
          this.content = "";
        }
        if (res.updatedAt === oldUpdatedAt) {
          isUpdate = false;
        } else {
          isUpdate = true;
          this.updatedAt = res.updatedAt;
        }
        const params = {
          pathId: this.$route.params.pathId,
          isUpdated: isUpdate,
        };
        pubExitEdit(params).then((res) => {});
        this.loading.close();
      });
    },
    catalogueUpdate() {},
    contentChange(content) {
      this.content = content;
    },
    userlistchange(list) {
      this.userListNow = list;
    },
    sendingFn(flag) {
      this.sending = flag;
    },
  },
};
</script>

<style scoped lang="scss">
.language-option {
  position: absolute;
  top: 20px;
  right: 20px;
}
.knowledge_wrap {
  width: 100%;
  height: 100%;
  .left-header {
    background: linear-gradient(
      90deg,
      rgba(0, 76, 170, 1) 34%,
      rgba(3, 122, 188, 1) 100%
    );
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    .topbar-logo {
      padding-left: 10px;
      padding-right: 17px;
      padding-top: 7px;
      max-height: 100%;
      max-width: 100%;
    }
    .name_logo {
      width: 231px;
      height: 36px;
    }
  }
  .not_edit_class {
    height: calc(100% - 60px);
    padding: 30px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    .not-edit-wrap {
      display: flex;
      justify-content: flex-start;
      height: 100%;
      padding-bottom: 10px;
      flex: 1 1 auto;
      overflow: hidden;
    }
  }
  .knowledge-edit-main {
    width: 100%;
    height: calc(100% - 200px);
    display: flex;
    justify-content: left;
    padding-bottom: 10px;
  }
}
</style>
<style>
.position_dom {
  background-color: rgb(255, 195, 0, 0.2);
}
</style>

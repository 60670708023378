<template>
  <div class="personal-avatar-root" :style="{ 'justify-content': flex }">
    <el-tooltip
      v-if="
        newAvatar &&
        (newAvatar.indexOf('.png') != -1 ||
          newAvatar.indexOf('.jpg') != -1 ||
          newAvatar.indexOf('.svg') != -1)
      "
      class="item"
      effect="dark"
      :content="nickname"
      :placement="placement"
      :disabled="isProjectDropdown"
    >
      <el-avatar
        v-if="refresh"
        ref="aaa"
        fit="fill"
        :size="size"
        :src="newAvatar"
        style="background-color: transparent"
      >
      </el-avatar>
    </el-tooltip>
    <el-tooltip
      v-else
      class="item"
      effect="dark"
      :content="nickname"
      :placement="placement"
      :disabled="isProjectDropdown"
    >
      <el-avatar ref="bbb" :size="size">
        {{ user_name }}
      </el-avatar>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    size: {
      type: Number,
      default: 40,
    },
    avatar: {
      type: String,
      default: "",
    },
    nickname: {
      type: String,
      default: "",
    },
    colorid: {
      type: String,
      // 控制颜色的   用户头像的话传accountId  项目头像传入id
      default: "5",
    },
    placement: {
      type: String,
      default: "left",
    },
    toolTipDisabled: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: String,
      default: "center",
    },
    isProjectDropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      refresh: true,
      newAvatar: "",
    };
  },
  computed: {
    user_name() {
      let name =
        this.nickname === "未分配"
          ? "?"
          : this.nickname.slice(0, 1).toUpperCase();
      return name;
    },
    color_id() {
      return this.colorid.toString();
    },
  },
  watch: {
    avatar: {
      handler(value) {
        this.refresh = false;

        this.$nextTick(() => {
          this.refresh = true;
          this.newAvatar = this.avatar;
          if (
            this.newAvatar &&
            this.newAvatar.indexOf("https://automind-oss.ytdevops.com") != -1
          ) {
            let reg = new RegExp("https://automind-oss.ytdevops.com", "g");
            this.newAvatar = this.newAvatar.replace(reg, "");
            this.newAvatar =
              "https://automind-oss.ytdevops.com" + this.newAvatar;
          }
        });
      },
    },
    color_id: {
      //监测color_id的变化
      handler() {
        try {
          this.color();
        } catch (error) {}
      },
    },
  },

  mounted() {
    try {
      let fontsize = this.size * 0.4 + "px";
      this.$refs.bbb.$el.style.fontSize = fontsize;
      this.color();
    } catch (error) {
      // console.log(error);
    }
    this.newAvatar = this.avatar;
    if (
      this.newAvatar &&
      this.newAvatar.indexOf("https://automind-oss.ytdevops.com") != -1
    ) {
      let reg = new RegExp("https://automind-oss.ytdevops.com", "g");
      this.newAvatar = this.newAvatar.replace(reg, "");
      this.newAvatar = "https://automind-oss.ytdevops.com" + this.newAvatar;
    }
  },
  methods: {
    color() {
      //确定颜色号 是由projectid或者是userid 最后一位字符的ascii的最后一位决定的
      let num = this.color_id.charCodeAt(this.color_id.length - 1).toString(); //获取projectid或者是userid最后一位的ascii
      let color = num[num.length - 1]; //获取ascii的最后一位
      this.get_color(color);
      // console.log(this.user_name, color);   //显示名字与对应颜色号
    },
    get_color(color) {
      switch (color) {
        case "0":
          this.rgb(238, 180, 34);
          break;
        case "1":
          this.rgb(160, 82, 45);
          break;
        case "2":
          this.rgb(255, 99, 71);
          break;
        case "3":
          this.rgb(147, 112, 219);
          break;
        case "4":
          this.rgb(149, 171, 144);
          break;
        case "5":
          this.rgb(255, 20, 147);
          break;
        case "6":
          this.rgb(122, 103, 238);
          break;
        case "7":
          this.rgb(180, 82, 205);
          break;
        case "8":
          this.rgb(229, 139, 134);
          break;
        case "9":
          this.rgb(117, 175, 207);
          break;
      }
    },
    rgb(r, g, b) {
      let rgb = "rgb" + "(" + r + "," + g + "," + b + ",0.8)";
      this.$refs.bbb.$el.style.backgroundColor = rgb;
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-avatar-root {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-avatar {
    // box-shadow: 0px 2px 4px rgb(128 128 128 / 61%);
    ::v-deep img {
      width: 100%;
    }
  }
}
</style>

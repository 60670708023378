import Quill from "quill";  //引入编辑器
import ImageResize from "quill-image-resize-module";
import QuillBetterTable from './quill-better-table.js'
import { ulid } from "ulid";
import loadingImg from '@/assets/img/loading.gif'
// oss认证
import { ossAuthorization, unloginOssAuthorization } from "@/utils/oss";
import { imageOnInternet, pubImageOnInternet } from "@/network/knowledge";
import { getToken } from '@/utils/auth'
import { sessionRead } from "@/utils/session";
const oss = {};
let interval = null;
//quill编辑器的字体
let fontArr = ['思源黑体', 'Microsoft-YaHei', 'Arial', 'SimSun', 'SimHei', 'KaiTi', 'FangSong'];  // 这里的顺序注意一下
let Font = Quill.import('formats/font');
Font.whitelist = fontArr; // 将字体加入到白名单 ,这里可在 /formats/fonts.js 中了解
Quill.register(Font, true);

let sizeArr = [
    '', '10px', '12px', '14px', '16px', '18px', "20px",
    '22px', '24px', '26px', '32px', '48px'
];
Quill.imports['attributors/style/size'].whitelist = sizeArr;
Quill.register(Quill.imports['attributors/style/size']);

// emoji
// const ImageBlot = Quill.import('formats/image');
const Inline = Quill.import('blots/inline')
// 扩展Quill内置的image格式
class EmojiBlot extends Inline {
    static blotName = 'emoji'; // blot的名称，需要唯一，防止冲突
    static tagName = 'span'; // 渲染的标签名
    static create(value) {
        const dom = super.create(value)
        dom.innerText = value
        return dom
    }
}
Quill.register('formats/emoji', EmojiBlot);

// 提取链接参数
function GetQueryString(name, href) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = href.match(reg); //获取url中"?"符后的字符串并正则匹配
    let context = "";
    if (r != null)
        context = decodeURIComponent(r[2]);
    reg = null;
    r = null;
    return context == null || context == "" || context == "undefined" ? "" : context;
}
// base64转file
function dataURLtoFile(dataurl, filename) {
    if (!dataurl) return;
    let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

function customHeader(client) {
    if ((getToken('Admin-Token'))) {
        ossAuthorization.call(oss);
        interval = setInterval(() => {
            ossAuthorization.call(oss);
        }, 20 * 60 * 1000);
    }
    class PlainResize extends ImageResize {
        checkImage = (evt) => {
            if (this.img) {
                if (evt.keyCode === 46 || evt.keyCode === 8) {
                    Quill.find(this.img).deleteAt(0);
                }
                this.hide();
            }
        };
    }
    Quill.register('modules/ImageResize', PlainResize);
    // 修改有序列表
    // const listFormats = Quill.import("formats/list");
    // class List extends listFormats {
    //     static create(value) {
    //         const node = super.create();
    //         if (typeof value === "string") {
    //             node.setAttribute('data-list', value);
    //         } else {
    //             node.setAttribute('data-list', value.value);
    //         }
    //         node.setAttribute(
    //             "id",
    //             value.id && !document.getElementById(value.id) ? value.id : ulid()
    //         );
    //         return node;
    //     }
    //     static formats(node) {
    //         let format = {
    //             value: node.getAttribute('data-list'),
    //         };
    //         if (node.hasAttribute("id")) {
    //             format.id = node.getAttribute("id");
    //         }
    //         return format;
    //     }
    //     format(name, value) {
    //         if (name === this.statics.blotName && value) {
    //             if (typeof value === "string") {
    //                 this.domNode.setAttribute('data-list', value);
    //             } else {
    //                 this.domNode.setAttribute('data-list', value.value);
    //             }
    //             this.domNode.setAttribute(
    //                 "id",
    //                 value.id && !document.getElementById(value.id) ? value.id : ulid()
    //             );
    //         } else {
    //             super.format(name, value);
    //         }
    //     }
    // }
    // Quill.register(List, true);
    // 每个H标签添加id
    const HeaderFormats = Quill.import("formats/header");

    class Header extends HeaderFormats {
        static create(value) {
            let node = super.create(value.header ? value.header : value);
            node.setAttribute(
                "id",
                value.id && !document.getElementById(value.id) ? value.id : ulid()
            );
            return node;
        }

        static formats(node) {
            let format = {
                header: node.nodeName.split("H")[1],
            };
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            }
            return format;
        }
    }

    Quill.register(Header, true);
    // 自定义行高属性
    // Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
    const Link = Quill.import("formats/link");

    class FileBlot extends Link {
        static create(value) {
            let node = super.create();
            node.target = "_blank"
            let newLink = '';
            if (value && !value.href) {
                newLink = value;
                if (value.indexOf("http://") !== 0 && value.indexOf("https://") !== 0 && value.indexOf("#") !== 0) {
                    newLink = "https://" + value
                } else if (value.indexOf("#") === 0) {
                    node.target = "_self"
                }
            } else if (value && value.href) {
                newLink = value.href;
                if (
                    value.href.indexOf("http://") !== 0 &&
                    value.href.indexOf("https://") !== 0 &&
                    value.href.indexOf("#") !== 0
                ) {
                    newLink = "https://" + value.href;
                } else if (value.href.indexOf("#") === 0) {
                    node.target = "_self"
                }
                if (value.innerText) {
                    node.innerText = value.innerText;
                }
            }
            node.href = newLink;
            return node;
        }
    }

    FileBlot.blotName = "link";
    FileBlot.tagName = "a";
    Quill.register(FileBlot);
    //每个块增加id
    const BlockBlot = Quill.import("blots/block");

    class CustomBlockBlot extends BlockBlot {
        static create(value) {
            const node = super.create(value);
            node.setAttribute(
                "id",
                value && value.id && !document.getElementById(value.id)
                    ? value.id
                    : ulid()
            );
            return node;
        }

        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            } else {
                format.id = ulid();
            }
            return format;
        }
    }

    Quill.register("blots/block", CustomBlockBlot);
    // 自定义高亮块
    class lightBlock extends BlockBlot {
        static create(value) {
            const node = super.create(value);
            node.setAttribute(
                "id",
                value && value.id && !document.getElementById(value.id)
                    ? value.id
                    : ulid()
            );
            return node;
        }
        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            } else {
                format.id = ulid();
            }
            return format;
        }
    }

    lightBlock.blotName = "lightBlock";
    lightBlock.className = "lightBlock";
    lightBlock.tagName = "p";
    Quill.register({
        "formats/lightBlock": lightBlock,
    });
    const Embed = Quill.import("blots/inline");
    //@人自定义dom
    class Span extends Embed {
        static create(value) {
            const node = super.create(value);
            node.setAttribute(
                "id",
                value && value.id && !document.getElementById(value.id)
                    ? value.id
                    : ulid()
            );
            node.setAttribute(
                "accountId",
                value && value.accountId ? value.accountId : ""
            );
            node.setAttribute("style", "color:rgb(64, 158, 255);");
            if (!value.id) {
                node.innerText = value.name;
            }
            return node;
        }

        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
                format.accountId = node.getAttribute("accountId");
            }
            return format;
        }
    }

    Span.blotName = "my-span";
    Span.className = "my-at";
    Span.tagName = "span";
    Quill.register({
        "formats/my-span": Span,
    });




    class A_link extends Embed {
        static create(value) {
            const node = super.create(value);

            node.setAttribute(
                "id", 'link#' + ulid()
            );
            node.setAttribute(
                "link", value.link
            );
            node.setAttribute(
                "type", value.type
            );
            node.setAttribute("style", "color:#06c;text-decoration: underline;");
            if (!value.id) {
                node.innerText = value.name;
            }
            return node;
        }

        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            }
            if (node.hasAttribute("id")) {
                format.link = node.getAttribute("link");
            }
            if (node.hasAttribute("id")) {
                format.type = node.getAttribute("type");
            }
            return format;
        }
    }

    A_link.blotName = "my-link";
    A_link.className = "my-alink";
    A_link.tagName = "span";
    Quill.register({
        "formats/my-link": A_link,
    });



    class A_view extends Embed {
        static create(value) {
            const node = super.create(value);

            node.setAttribute(
                "id", 'link#' + ulid()
            );
            node.setAttribute(
                "link", value.link
            );
            node.setAttribute(
                "type", value.type
            );
            node.setAttribute(
                "src", value.link
            );
            node.setAttribute(
                "width", "1500px"
            );
            node.setAttribute(
                "height", '600px'
            );
            node.setAttribute("style", "color:#06c;text-decoration: underline;");
            if (!value.id) {
                node.innerText = 'iframe';
            }
            return node;
        }

        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            }
            if (node.hasAttribute("id")) {
                format.link = node.getAttribute("link");
            }
            if (node.hasAttribute("id")) {
                format.type = node.getAttribute("type");
            }
            return format;
        }
    }

    A_view.blotName = "my-view";
    A_view.className = "my-aview";
    A_view.tagName = "iframe";
    Quill.register({
        "formats/my-view": A_view,
    });


    class A_card_link extends Embed {
        static create(value) {
            const node = super.create(value);

            node.setAttribute(
                "id", 'link#' + value.card_id + '#card_link'
            );
            node.setAttribute(
                "link", value.link
            );
            node.setAttribute(
                "type", value.type
            );
            node.setAttribute("style", "color:#06c;font-size:18px;border: 2px solid #909399;border-bottom: none;padding: 10px 20px 0 10px;border-top-left-radius: 4px;border-top-right-radius: 4px;");
            if (!value.id) {
                node.innerText = value.link;
            }
            return node;
        }

        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            }
            if (node.hasAttribute("id")) {
                format.link = node.getAttribute("link");
            }
            if (node.hasAttribute("id")) {
                format.type = node.getAttribute("type");
            }
            return format;
        }
    }
    A_card_link.blotName = "my-card_link";
    A_card_link.className = "my-acard_link";
    A_card_link.tagName = "div";
    Quill.register({
        "formats/my-card_link": A_card_link,
    });


    class A_title extends Embed {
        static create(value) {
            const node = super.create(value);

            node.setAttribute(
                "id", 'link#' + value.card_id + '#title'
            );
            node.setAttribute(
                "link", value.link
            );
            node.setAttribute(
                "type", value.type
            );
            node.setAttribute("style", "color:black;font-size:22px;border: 2px solid #909399;border-bottom: none;border-top: none;padding: 10px 20px 0 10px;");
            if (!value.id) {
                node.innerText = value.title;
            }
            return node;
        }

        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            }
            if (node.hasAttribute("id")) {
                format.link = node.getAttribute("link");
            }
            if (node.hasAttribute("id")) {
                format.type = node.getAttribute("type");
            }
            return format;
        }
    }
    A_title.blotName = "my-title";
    A_title.className = "my-atitle";
    A_title.tagName = "div";
    Quill.register({
        "formats/my-title": A_title,
    });

    class A_desc extends Embed {
        static create(value) {
            const node = super.create(value);

            node.setAttribute(
                "id", 'link#' + value.card_id + '#desc'
            );
            node.setAttribute(
                "link", value.link
            );
            node.setAttribute(
                "type", value.type
            );
            node.setAttribute("style", "color:#909399;font-size:18px;font-size: 18px;border: 2px solid #909399;border-top: none;padding: 10px;border-bottom-right-radius: 4px;border-bottom-left-radius: 4px;}");
            if (!value.id) {
                node.innerText = value.desc;
            }
            return node;
        }
        static formats(node) {
            let format = {};
            if (node.hasAttribute("id")) {
                format.id = node.getAttribute("id");
            }
            if (node.hasAttribute("id")) {
                format.link = node.getAttribute("link");
            }
            if (node.hasAttribute("id")) {
                format.type = node.getAttribute("type");
            }
            return format;
        }
    }
    A_desc.blotName = "my-desc";
    A_desc.className = "my-adesc";
    A_desc.tagName = "div";
    Quill.register({
        "formats/my-desc": A_desc,
    });


    Quill.register({
        'modules/better-table': QuillBetterTable
    }, true)
    //行距代码
    const Parchment = Quill.import("parchment");

    class lineHeightAttributor extends Parchment.ClassAttributor {
    }

    const lineHeightStyle = new lineHeightAttributor("lineHeight", "ql-lineHeight", {
        scope: Parchment.Scope.INLINE, whitelist: ["1", "1-5", "1-75", "2", "3", "4", "5"]
    });
    Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
    // 图片复制功能
    const BlockEmbed = Quill.import('blots/embed');

    class CustomImage extends BlockEmbed {
        static create(value) {
            let node = super.create();
            // 图片为base64格式
            if (typeof value === "string" && value.startsWith("data:image/")) {
                node.setAttribute('src', loadingImg)
                const file = dataURLtoFile(value, ulid())
                let ossClient = client || oss.client;
                ossClient.put(`/comment/${new Date().getTime()}${file.name}`,
                    file
                ).then((res) => {
                    node.setAttribute('src', res.url);
                }).catch((err) => {
                    node.setAttribute('src', null)
                })
                // drawio
            } else if (typeof value.src === "string" && value.src.startsWith("data:image/") && value.drawio) {
                node.setAttribute('src', loadingImg)
                const file = dataURLtoFile(value.src, ulid())
                let ossClient = client || oss.client;
                ossClient.put(`/comment/${new Date().getTime()}${file.name}`,
                    file
                ).then((res) => {
                    node.setAttribute('src', res.url);
                }).catch((err) => {
                    node.setAttribute('src', null)
                })
                node.setAttribute('drawio', true);
                value.width ? node.width = value.width : '';
                // 图片已经是oss地址
            } else if (typeof value.src === "string" &&
                (value.src.indexOf("automind-oss.ytdevops.com") != -1 ||
                    value.src.indexOf("automind-global-oss.ytdevops.com") != -1 ||
                    value.src.indexOf("ytdevops-automind.oss-cn-shanghai.aliyuncs.com") != -1 ||
                    value.src.indexOf("ytdevops-automind-sg.oss-ap-southeast-1.aliyuncs.com") != -1)) {
                node.setAttribute('src', value.src);
                value.width ? node.width = value.width : '';
            } else if (typeof value.src === "string" && value.src.startsWith("http")) {
                node.setAttribute('src', loadingImg)
                if (sessionRead('internetToken')) {
                    pubImageOnInternet({
                        imageUrl: value.src,
                        token: sessionRead('internetToken'),
                        pathId: sessionRead('pathId')
                    }).then((res) => {
                        node.setAttribute('src', res)
                        value.width ? node.width = value.width : '';
                    }).catch((err) => {
                        node.setAttribute('src', null)
                    })
                } else {
                    imageOnInternet({
                        imageUrl: value.src
                    }).then((res) => {
                        node.setAttribute('src', res)
                        value.width ? node.width = value.width : '';
                    }).catch((err) => {
                        node.setAttribute('src', null)
                    })
                }
            }
            if (value.drawio) {
                node.setAttribute('drawio', value.drawio)
            }
            node.style.display = "inline-block"
            return node;
        }

        static formats(node) {
            return {
                src: node.getAttribute('src'),
                width: node.getAttribute('width'),
                drawio: node.getAttribute('drawio')
            };
        }

        static value(node) {
            return {
                src: node.getAttribute('src'),
                width: node.getAttribute('width'),
                drawio: node.getAttribute('drawio')
            };
        }
    }

    CustomImage.blotName = 'image';
    CustomImage.tagName = 'img';
    Quill.register(CustomImage);
}
function stopInterval() {
    if (interval) {
        clearInterval(interval);
        interval = null;
    }
}


export { customHeader, stopInterval }
<template>
  <div id="no-hidden-dialog" class="ql-snow no-hidden-dialog">
    <div class="ql-editor">
      <div id="document-wrap" class="document-wrap">
        <el-img-viewer
          v-if="isViewerVisible"
          class="viewer"
          :url-list="nowImgsrc"
          :on-close="closeImgViewer"
        ></el-img-viewer>
        <div id="document-content" @click="showImage">
          <div v-html="data" @click="checktext"></div>
        </div>
        <div id="toolbar-container" class="toolbar-container"></div>
        <div id="hide-wrap" class="hide-wrap"></div>
      </div>
    </div>
    <div class="question-button" @click="showQuestionDialog">
      <span>
        <el-tooltip class="item" effect="dark" placement="bottom">
          <div slot="content">{{ $t("pubKnowledge.RaiseQuestion") }}</div>
          <i
            class="el-icon-question"
            style="
              font-size: 24px;
              vertical-align: middle;
              color: rgba(128, 128, 128, 1);
            "
          ></i>
        </el-tooltip>
      </span>
    </div>
    <div id="menu">
      <div>
        <div
          v-for="(item, index) in rightMenu"
          :key="index"
          @click="menuClick(index)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
    <el-dialog
      :visible.sync="questionDialogVisible"
      :title="$t('pubKnowledge.SubmitQuestion')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="questionForm"
        :model="questionForm"
        :rules="rules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          :label="$t('pubKnowledge.QuestionDescription')"
          prop="content"
        >
          <el-input v-model="questionForm.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('pubKnowledge.PhoneNumber')"
          prop="phoneNumber"
        >
          <el-input v-model="questionForm.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pubKnowledge.Email')" prop="email">
          <el-input v-model="questionForm.email"></el-input>
        </el-form-item>
      </el-form>
      <el-alert
        :title="$t('pubKnowledge.RequireResponseContact')"
        type="info"
        show-icon
        :closable="false"
      >
      </el-alert>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="questionCancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" @click="questionComfrirm"
          >{{ $t("pubKnowledge.Submit") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      id="registerDialog"
      :modal="false"
      :visible.sync="registerVisible"
      width="580px"
    >
      <span>{{ $t("pubKnowledge.registerTip") }}</span>
      <span slot="footer" class="registerDialog-footer">
        <el-button @click="registerVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="goRegister">{{
          $t("addLogin.register.title")
        }}</el-button>
      </span>
    </el-dialog>
    <div class="goTop">
      <i class="iconfont icon-a-huaban18" @click="goTop"></i>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import { ulid } from "ulid";
import { sendQuestions } from "@/network/knowledge";
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    internetToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: "",
      outerWidth: 0,
      nowImgsrc: [],
      isViewerVisible: false,
      questionDialogVisible: false,
      questionForm: {
        content: "",
        email: "",
        phoneNumber: "",
      },
      rules: {
        content: [
          {
            required: true,
            message: this.$t("pubKnowledge.FillQuestionDescription"),
            trigger: "blur",
          },
        ],
      },
      // 上次定位的id
      lastHash: "",
      rightMenu: [
        {
          label: this.$t("knowledge.GenerateSharingLink"),
        },
      ],
      registerVisible: false,
      timer: null,
    };
  },
  watch: {
    content(newval) {
      this.gethtml();
    },
    $route(val) {
      if (val.hash) {
        this.locationElement(val.hash);
      }
    },
  },
  mounted() {
    this.initQuill();
    this.listenerwidth();
    this.gethtml();
    this.initRightClick();
    const goTop = document.getElementsByClassName("goTop")[0];
    goTop.style.display = "none";
  },
  beforeDestroy() {
    let scrollContent = document.getElementById("no-hidden-dialog");
    if (scrollContent) {
      scrollContent.removeEventListener(
        "scroll",
        this.debounceScroll(scrollContent, 150)
      );
    }
  },
  methods: {
    checktext(event) {
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;

      if (
        selection.toString() != "" &&
        (commonAncestor.id == undefined || commonAncestor.id != "")
      ) {
        this.setDownposition();
        event.stopPropagation();
      }
    },
    initRightClick() {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("document-content");
      const wrap2 = document.getElementById("document-wrap");
      wrap.oncontextmenu = function (evt) {
        self.setDownposition(evt);
      };
      wrap2.onclick = function () {
        self.lineId
          ? (document.getElementById(self.lineId).style.backgroundColor =
              "#fff")
          : "";
        menu.style.display = "none";
      };
    },
    setDownposition(evt) {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("document-content");
      const wrap2 = document.getElementById("document-wrap");
      // 自定义body元素的鼠标事件处理函数
      const e = evt || window.event;
      e.preventDefault(); //阻止系统右键菜单
      // 显示自定义的菜单调整位置
      self.lineId
        ? (document.getElementById(self.lineId).style.backgroundColor = "#fff")
        : "";
      self.lineId = e.target.id ? e.target.id : e.target.parentNode.id;
      document.getElementById(self.lineId).style.backgroundColor = "#f0f7ff";
      if (self.lineId) {
        menu.style.display = "block";
        menu.style.left = e.clientX + "px";
        menu.style.top = e.clientY + wrap.scrollTop + "px";
      }
    },

    // 展示提出问题弹窗
    showQuestionDialog() {
      this.questionDialogVisible = true;
      this.questionForm = {
        content: "",
        email: "",
        phoneNumber: "",
      };
    },
    questionComfrirm() {
      this.$refs.questionForm.validate((valid) => {
        if (valid) {
          const params = {
            pathId: this.$route.params.pathId,
            data: this.questionForm,
          };
          sendQuestions(params).then((res) => {
            this.questionDialogVisible = false;
            this.$message({
              type: "success",
              message: this.$t("pubKnowledge.QuestionSubmittedSuccessfully"),
            });
          });
        }
      });
    },
    questionCancel() {
      this.questionDialogVisible = false;
    },
    showImage(event) {
      if (event.target.localName === "img") {
        this.nowImgsrc = [event.target.currentSrc];
        this.isViewerVisible = true;
      }
      if (event.target.id.split("#")[0] === "link") {
        let l = event.target.attributes.link.value;
        console.log();

        window.open(
          (l.slice(0, 4) == "http" ? "" : "//") +
            event.target.attributes.link.value,
          "_blank"
        );
      }
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    // 监听宽度变化
    listenerwidth() {
      const self = this;
      this.observer = new ResizeObserver((entries) => {
        entries.forEach((item) => {
          self.outerWidth = item.borderBoxSize[0].inlineSize;
        });
      });
      let content = document.getElementById("document-content");
      this.observer.observe(content);
    },
    initQuill() {
      this.quill = new Quill("#hide-wrap", {
        modules: {
          toolbar: "#toolbar-container",
          history: {
            delay: 2000,
            maxStack: 500,
          },
        },
        theme: "snow",
      });
    },
    gethtml() {
      if (this.content) {
        const delta = JSON.parse(this.content);
        this.quill.setContents(delta);
        this.data = this.quill.root.innerHTML;
        this.$nextTick(() => {
          this.locationElement();
          let scrollContent = document.getElementById("no-hidden-dialog");
          if (scrollContent) {
            scrollContent.addEventListener(
              "scroll",
              this.debounceScroll(scrollContent, 80)
            );
          }
        });
      } else {
        this.data = "";
      }
    },
    debounceScroll(dom, delay) {
      const that = this;
      return function () {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        let scrollContent = dom;
        this.timer = setTimeout(() => {
          const clientHeight = scrollContent.clientHeight;
          const scrollTop = scrollContent.scrollTop;
          const scrollHeight = scrollContent.scrollHeight;
          if (Math.ceil(clientHeight + scrollTop) >= scrollHeight) {
            that.registerVisible = true;
          }
        }, delay);
      };
    },
    // 定位参数dom
    locationElement(hash) {
      if ((this.$route.query && this.$route.query.elementId) || hash) {
        this.lastHash
          ? document
              .getElementById(this.lastHash)
              .classList.remove("position_dom")
          : "";
        const id = hash ? hash.split("#")[1] : this.$route.query.elementId;
        const dom = document.getElementById(id);
        if (dom) {
          dom.classList.add("position_dom");
        }
        window.location.href = "#" + id;
        this.lastHash = id;
      }
    },
    // 右键菜单点击事件
    menuClick(index) {
      switch (index) {
        case 0:
          if (this.lineId) {
            const link = window.location.href.split("?")[0];
            const query = `?elementId=${this.lineId}`;
            let text1 = this.$refs["text_area"];
            text1.innerText = link + query;
            text1.select();
            document.execCommand("copy");
            const menu = document.getElementById("menu");
            menu.style.display = "none";
            this.$message({
              type: "success",
              message: this.$t("knowledge.copied"),
            });
          }
          break;
      }
    },
    goRegister() {
      const { href } = this.$router.resolve({
        path: `/login/register`,
      });
      window.open(href, "_blank");
      this.registerVisible = false;
    },
    goTop() {
      const doc = document.getElementById("no-hidden-dialog");
      if (doc) {
        doc.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.at_position {
  position: absolute;
}
#toolbar-container2 {
  display: none;
}
#editor-container2 {
  width: 100%;
  border-top: 1px solid #ccc;
}
.ql-snow,
.ql-edit {
  width: 65%;
  flex-grow: 1;
  overflow-y: scroll;
  .ql-editor {
    height: fit-content;
  }
}
.document-wrap {
  width: 100%;
  padding-left: 84px;
  padding-bottom: 40px;
  text-align: left;
  overflow-x: visible;
  position: relative;
  overflow-y: visible;
  .hide-wrap {
    display: none;
  }
  .toolbar-container {
    display: none;
  }
}
#menu {
  display: none;
  position: absolute;
  width: 130px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;
      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
::v-deep #registerDialog {
  position: unset !important;
  z-index: -1 !important;
}
::v-deep #registerDialog .el-dialog {
  z-index: 9999;
  position: absolute;
  margin: 0;
  right: 30px;
  bottom: 78px;
  height: 200px;
  .el-dialog__body {
    margin-top: 10px;
    font-size: 20px;
  }
  .el-dialog__footer {
    padding-top: 40px !important;
    .el-button {
      width: 100px;
      height: 40px;
    }
  }
}
::v-deep .my-alink,
::v-deep .my-atitle,
::v-deep .my-adesc,
::v-deep .my-acard_link {
  cursor: pointer;
}
.goTop {
  position: absolute;
  bottom: 110px;
  right: 50px;
  border-radius: 50%;
  background-color: white;
  i {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: rgba(145, 147, 153, 1);
  }
}
</style>
<style lang="scss">
.no-hidden-dialog {
  overflow-y: scroll;
  max-height: 90%;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
  .el-dialog {
    overflow: visible;
  }
  .question-button {
    position: absolute;
    right: 50px;
    bottom: 50px;
    cursor: pointer;
  }
}
.document-wrap {
  & > div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-right: 200px !important;
      /*no*/
    }
  }
}
</style>
<style>
.position_dom {
  background-color: rgb(255, 195, 0, 0.2);
}
</style>

<template>
  <div class="title-wrap">
    <el-tooltip
      v-ak-tooltip-auto-show
      effect="dark"
      placement="bottom"
      :content="documentInfo.label"
    >
      <div
        class="title"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        {{ documentInfo.label }}
      </div>
    </el-tooltip>
    <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
      <el-button
        type="primary"
        style="height: 40px; margin-left: 10px"
        @click="subscribe"
        >{{$t('pubKnowledge.subscribe')}}</el-button
      >
      <!-- 编辑按钮转移到此处 -->
      <el-button
        v-if="internetEdit"
        type="primary"
        style="width: 100px; height: 40px; margin-right: 10px"
        @click="editFn"
        >{{ $t("btn.editBtn") }}</el-button
      >
    </div>
    <el-dialog
      :title="$t('addLogin.forgetPsd.place1')"
      :visible.sync="dialogVisible"
      width="25%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('btn.cancelBtn')}}</el-button>
        <el-button type="primary" @click="confirmSubscribe">{{$t('btn.confirmBtn')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {postSubscribe} from '@/network/knowledge/index.js'
export default {
  components: {},
  props: {
    documentInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    internetEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var validateEamil = (rule, value, callback) => {
      let emailRegExp = /^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '') {
        callback(new Error(this.$t('pubKnowledge.inputEamil')));
      } else if(!emailRegExp.test(value)){
        callback(new Error(this.$t('pubKnowledge.checkEmail')));        
      }else{
        callback();
      }
    };
    return {
      dialogVisible:false,
      ruleForm:{
        email:'',
      },
      rules: {
        email:{validator:validateEamil,trigger: 'blur'}
      }
    };
  },
  watch: {},
  mounted() {},
  methods: {
    subscribe(){
      this.dialogVisible = true
    },
    confirmSubscribe(){
      this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              pathId: this.$route.params.pathId,
              email: this.ruleForm.email
            }
            postSubscribe(params).then(res=>{
              this.$message({
                    message: this.$t('pubKnowledge.subscribeSuccessful'),
                    type: "success",
                });
              this.ruleForm = {
                email:'',
              }
              this.dialogVisible = false
            })
          } else {
            return false;
          }
        });
    },
    editFn() {
      this.$emit("editFn");
    },
  },
};
</script>

<style scoped lang="scss">
.title-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  justify-content: space-between;
  text-align: left;
  min-height: 32px;
  width: 100%;
  position: relative;
  .title {
    font-size: 32px;
    font-weight: 500;
    padding-right: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title_status {
    position: absolute;
    z-index: 9;
  }
}
.tool-wrap {
  text-align: right;
  padding-right: 86px;
  height: 50px;
  line-height: 50px;
  position: relative;
}
</style>

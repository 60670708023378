let emojiData = [
    "😀",
    "😁",
    "😂",
    "🤣",
    "😃",
    "😄",
    "😅",
    "😆",
    "😉",
    "😊",
    "😋",
    "😎",
    "😍",
    "😘",
    "😗",
    "😙",
    "😚",
    "🙂",
    "🤗",
    "🤩",
    "🤔",
    "🤨",
    "😐",
    "😑",
    "😶",
    "🙄",
    "😏",
    "😣",
    "😥",
    "😮",
    "🤐",
    "😯",
    "😪",
    "😫",
    "😴",
    "😌",
    "😛",
    "😜",
    "😝",
    "🤤",
    "😒",
    "😓",
    "😔",
    "😕",
    "🙃",
    "🤑",
    "😲",
    "🙁",
    "😖",
    "😞",
    "😟",
    "😤",
    "😢",
    "😭",
    "😦",
    "😧",
    "😨",
    "😩",
    "🤯",
    "😬",
    "😰",
    "😱",
    "😳",
    "🤪",
    "😵",
    "😡",
    "😠",
    "🤬",
    "🤳",
    "💪",
    "👈",
    "👉",
    "☝",
    "👆",
    "🖕",
    "👇",
    "✌",
    "🤞",
    "🖖",
    "🤘",
    "🤙",
    "🖐",
    "✋",
    "👌",
    "👍",
    "👎",
    "✊",
    "👊",
    "🤛",
    "🤜",
    "🤚",
    "👋",
    "🤟",
    "✍",
    "👏",
    "👐",
    "🙌",
    "🤲",
    "🙏",
    "🤝",
    "💅",
    "👂",
    "👃",
    "👣",
    "👀",
    "🧠",
    "👅",
    "👄",
    "🙁",
    "😖",
    "😞",
    "😟",
    "😤",
    "😢",
    "😭",
    "😦",
    "😧",
    "😨",
    "😩",
    "🤯",
    "😬",
    "😰",
    "😱",
    "😳",
    "🤪",
    "😵",
    "😡",
    "😠",
    "🤬",
    "♀",
    "♂",
    "⚕",
    "♻",
    "⚜",
    "🔱",
    "📛",
    "🔰",
    "⭕",
    "✅",
    "☑",
    "✔",
    "✖",
    "❌",
    "❎",
    "➕",
    "➖",
    "➗",
    "➰",
    "➿",
    "〽",
    "✳",
    "✴",
    "❇",
    "‼",
    "⁉",
    "❓",
    "❔",
    "❕",
    "❗",
    "〰",
    "©",
    "®",
    "™",
    "💯",
]

export default emojiData;
<template>
  <mavon-editor
    ref="md"
    v-model="value"
    :language="language"
    :toolbars-flag="false"
  />
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  computed: {
    language() {
      return localStorage.getItem("lang");
    },
  },
};
</script>

<style></style>

<template>
  <div :id="container">
    <span class="ql-formats">
      <button @click="undo">
        <i class="iconfont icon-go-back" style="font-size: 20px" />
      </button>
      <button @click="redo">
        <i class="iconfont icon-go-forward" style="font-size: 20px" />
      </button>
      <button @click="format">
        <i class="iconfont icon-format" style="font-size: 20px" />
      </button>
      <button class="ql-clean"></button>
    </span>
    <span class="ql-formats">
      <select class="ql-font">
        <option value="思源黑体">{{ $t("knowledge.SourceHanSans") }}</option>
        <option value="Microsoft-YaHei">
          {{ $t("knowledge.MicrosoftYaHei") }}
        </option>
        <option value="Arial">Arial</option>
        <option value="SimSun">{{ $t("knowledge.SimSun") }}</option>
        <option value="SimHei">{{ $t("knowledge.SimHei") }}</option>
        <option value="KaiTi">{{ $t("knowledge.KaiTi") }}</option>
        <option value="FangSong">{{ $t("knowledge.FangSong") }}</option>
      </select>
      <select class="ql-size">
        <option
          v-for="(item, index) in sizeList"
          :key="index"
          :value="item.value"
          :selected="item.selected"
        >
          {{ item.label }}
        </option>
      </select>
      <select class="ql-lineHeight">
        <option value="1">1{{ $t("knowledge.lineSpacing") }}</option>
        <option value="1-5">1.5{{ $t("knowledge.lineSpacing") }}</option>
        <option value="1-75">1.75{{ $t("knowledge.lineSpacing") }}</option>
        <option value="2">2{{ $t("knowledge.lineSpacing") }}</option>
        <option value="3">3{{ $t("knowledge.lineSpacing") }}</option>
        <option value="4">4{{ $t("knowledge.lineSpacing") }}</option>
        <option value="5">5{{ $t("knowledge.lineSpacing") }}</option>
      </select>
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
    </span>
    <span class="ql-formats">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-script" value="sub"></button>
      <button class="ql-script" value="super"></button>
    </span>
    <span class="ql-formats">
      <select id="ql-header" class="ql-header">
        <option value="">{{ $t("knowledge.body") }}</option>
        <option value="1">{{ $t("knowledge.Heading1") }}</option>
        <option value="2">{{ $t("knowledge.Heading2") }}</option>
        <option value="3">{{ $t("knowledge.Heading3") }}</option>
        <option value="4">{{ $t("knowledge.Heading4") }}</option>
        <option value="5">{{ $t("knowledge.Heading5") }}</option>
        <option value="6">{{ $t("knowledge.Heading6") }}</option>
      </select>
      <button id="ql-code-block" class="ql-code-block"></button>
    </span>
    <span class="ql-formats">
      <button id="ql-list1" class="ql-list" value="ordered"></button>
      <button id="ql-list2" class="ql-list" value="bullet"></button>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-direction" value="rtl"></button>
      <select class="ql-align"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-link" @click.stop="add_link"></button>
      <button id="ql-video" class="ql-video"></button>
    </span>
    <span class="ql-formats">
      <button
        id="ql-better-table"
        class="ql-better-table"
        style="position: relative"
        @click.stop="showTableListFn"
      >
        <i class="el-icon-s-grid table-icon"></i>
        <div
          v-if="showTableList"
          class="table-list"
          @mouseleave="tableListLeave"
          @click.stop="insertTable"
        >
          <div>
            {{ `${mouseRowCol.row}x${mouseRowCol.col}` }}
          </div>
          <div v-for="(row, index) in 10" :key="index">
            <div
              v-for="(col, index2) in 10"
              :key="index2"
              :class="{
                'list-back-color':
                  col <= mouseRowCol.col && row <= mouseRowCol.row,
              }"
              @mousemove="tableListMove(row, col)"
            ></div>
          </div>
        </div>
      </button>
      <button
        id="ql-highlight"
        style="padding-top: 2px"
        @click.stop="insertLightBlock"
      >
        <i class="iconfont icon-highlight" style="font-size: 20px"></i>
      </button>
      <button
        id="ql-markdown"
        style="padding-top: 1px"
        class="ql-markdown"
        @click="showMarkDown"
      >
        <i class="iconfont icon-markdown" style="font-size: 20px"></i>
      </button>
      <button id="ql-emoji" style="padding-top: 1px" class="ql-emoji">
        <el-popover placement="bottom-start" width="380" trigger="hover">
          <div class="emojiPo">
            <span
              v-for="(item, index) in emojiData"
              :key="index"
              style="font-size: 20px; cursor: pointer; padding: 5px"
              @click="showEmoji(item)"
              >{{ item }}</span
            >
          </div>
          <el-button slot="reference">😀</el-button>
        </el-popover>
      </button>
      <button id="ql-drawio" class="ql-drawio" style="margin-left: 6px">
        <drawio
          ref="drawio"
          :client="client"
          :quill="quill"
          :img-dom="imgDom"
          @clearImgDom="clearImgDom"
        ></drawio>
      </button>
      <button id="ql-ai" class="ql-ai" @click="showAi">
        <i class="iconfont icon-AIbuchong" style="font-size: 20px"></i>
      </button>
    </span>
    <el-dialog
      :visible.sync="markdownVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="70%"
    >
      <span><markdown-editor v-if="showMark" ref="md"></markdown-editor></span>
      <span slot="footer" style="display: block; height: 40px">
        <el-button
          style="
            background-color: #174fa9;
            border-color: #174fa9;
            width: fit-content;
            float: right;
            margin: 10px;
          "
          type="primary"
          @click="markdownDialogConfirm"
          >{{ $t("btn.insert") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="ai_show"
      :close-on-click-modal="false"
      width="60%"
      :title="$t('knowledge.knowledge_ai.title1')"
      append-to-body
    >
      <el-input
        v-if="ai_select"
        ref="ai_adilog1"
        v-model="ai_form.ai_text"
        type="textarea"
        :rows="2"
        :placeholder="$t('knowledge.knowledge_ai.placeholder1')"
        style="margin-bottom: 20px"
        maxlength="1000"
        show-word-limit
      >
      </el-input>

      <el-input
        v-if="ai_file"
        ref="ai_adilog2"
        v-model="ai_form.ai_text"
        type="textarea"
        :rows="2"
        :placeholder="$t('knowledge.knowledge_ai.placeholder1')"
        style="margin-bottom: 20px"
        maxlength="1000"
        show-word-limit
      >
      </el-input>

      <el-form
        ref="ai-form"
        class="ai-form"
        :model="ai_form"
        :rules="rules"
        size="mini"
        label-position="top"
      >
        <div
          style="
            display: grid;
            align-items: center;
            justify-items: start;
            grid-template-columns: max-content max-content max-content;
          "
        >
          <el-form-item prop="select">
            <el-select
              v-model="ai_form.select"
              :placeholder="$t('knowledge.knowledge_ai.placeholder2')"
              style="margin-right: 5px"
              size="medium"
              :disabled="robotTyping"
            >
              <el-option
                v-for="item in ai_ops"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <div
            v-if="ai_form.select == 'GIVEN_CODE_CREATE'"
            style="display: flex; align-items: center"
          >
            <div>
              {{ $t("knowledge.knowledge_ai.code_lang") }}
              <span style="color: red">*</span>
            </div>
            <el-form-item
              prop="lang"
              style="margin-left: 5px; margin-right: 5px"
            >
              <el-input v-model="ai_form.lang" size="medium"></el-input>
            </el-form-item>
          </div>
          <div
            v-if="ai_form.select == 'generateDiagram'"
            style="display: flex; align-items: center"
          >
            <el-select
              v-model="ai_form.aiSelect"
              :placeholder="$t('node.ai.placeholder2')"
              style="margin-right: 20px"
              size="medium"
            >
              <el-option
                v-for="item in aiOps"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-form-item>
            <el-button
              type="text"
              size="medium"
              :loading="robotTyping"
              :disabled="submit_btn()"
              style="display: flex; align-items: center"
              @click="submitForm('ai-form')"
            >
              <i
                v-if="submit_btn()"
                class="iconfont icon-shengcheng"
                style="font-size: 28px; color: grey; cursor: not-allowed"
              ></i>
              <i
                v-else
                class="iconfont icon-shengcheng"
                style="font-size: 28px; color: rgb(0, 76, 170)"
              ></i>
            </el-button>
          </el-form-item>
        </div>
      </el-form>

      <div
        v-show="show_res && ai_form.select == 'GIVEN_CONTENT_PIC'"
        id="ai_res"
        :class="'ai_res_style'"
        v-html="ai_res"
      ></div>
      <div
        v-show="
          show_res &&
          ai_form.select != 'GIVEN_CONTENT_PIC' &&
          ai_form.select != 'generateDiagram'
        "
        id="ai_table"
        :class="robotTyping ? 'ai_res_blink ai_table_style' : 'ai_table_style'"
        v-html="ai_table"
      ></div>
      <div
        v-show="show_res && ai_form.select === 'generateDiagram'"
        class="mermaid-wrap"
      >
        <div>
          <el-input
            ref="ai_aiCode"
            v-model="ai_form.aiCode"
            type="textarea"
            :rows="20"
            :placeholder="$t('node.ai.placeholder1')"
            style="margin-bottom: 20px"
            maxlength="5000"
            show-word-limit
          >
          </el-input>
        </div>
        <div v-if="ai_form.aiCode" @click="zoomMermaid">
          <vue-mermaid-string id="svgdom" :value="ai_form.aiCode" />
        </div>
      </div>
      <div
        v-show="show_res && !robotTyping"
        slot="footer"
        class="dialog-footer"
      >
        <el-tooltip
          v-if="ai_form.select == 'GIVEN_CONTENT_PIC'"
          effect="dark"
          :content="$t('knowledge.knowledge_ai.placeholder3')"
          placement="top"
        >
          <el-button type="info" @click="submitForm('ai-form')">{{
            $t("knowledge.knowledge_ai.btn1")
          }}</el-button>
        </el-tooltip>

        <el-button v-else type="info" @click="bumanyi_click">{{
          $t("knowledge.knowledge_ai.btn2")
        }}</el-button>
        <el-button type="primary" class="button-confirm" @click="manyi_click">{{
          $t("knowledge.knowledge_ai.btn3")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="bumanyi_show"
      :close-on-click-modal="false"
      width="50%"
      :title="$t('knowledge.knowledge_ai.title2')"
      append-to-body
      class="ai_bumanyi"
      @close="before_bumanyi"
    >
      <el-alert
        v-show="bumanyi2_show"
        :title="$t('knowledge.knowledge_ai.title3')"
        type="warning"
        show-icon
        :closable="false"
        style="background-color: #edf6fb; margin-bottom: 20px"
      >
      </el-alert>
      <div v-if="languageType != 'en-US'">
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form1") }}
          <el-input
            v-model="bumanyi_form.a"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 150px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form2")
          }}<el-input
            v-model="bumanyi_form.b"
            :placeholder="$t('knowledge.knowledge_ai.placeholder5')"
            size="mini"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form3")
          }}<el-input
            v-model="bumanyi_form.c"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input
          >{{ $t("knowledge.knowledge_ai.bumanyi_form4") }}
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form5") }}
          <el-input
            v-model="bumanyi_form.d"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
      </div>
      <div v-else>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form1") }}
          <el-input
            v-model="bumanyi_form.a"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 150px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          I hope to

          <el-input
            v-model="bumanyi_form.b"
            :placeholder="$t('knowledge.knowledge_ai.placeholder5')"
            size="mini"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
          the above content
        </div>
        <div>
          To achieve the goal of

          <el-input
            v-model="bumanyi_form.c"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form5") }}
          <el-input
            v-model="bumanyi_form.d"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
      </div>
      <div v-show="!show_res" slot="footer" class="dialog-footer">
        <el-button
          type="text"
          size="medium"
          :loading="robotTyping"
          style="display: flex; align-items: center"
          :disabled="bumanyi_form.b < 1"
          @click="bumanyi_shengcheng"
        >
          <i
            v-if="bumanyi_form.b < 1"
            class="iconfont icon-shengcheng"
            style="font-size: 28px; color: grey; cursor: not-allowed"
          ></i>
          <i
            v-else
            class="iconfont icon-shengcheng"
            style="font-size: 28px; color: rgb(0, 76, 170)"
          ></i>
        </el-button>
      </div>

      <!-- <div
        v-show="show_res && ai_form.select != 'TESTCASE_OUTLINE_CREATE'"
        id="ai_res2"
        :class="robotTyping ? 'ai_res_blink ai_res_style' : 'ai_res_style'"
        v-html="ai_res"
      ></div> -->
      <div
        v-show="show_res && ai_form.select !== 'generateDiagram'"
        id="ai_table2"
        :class="robotTyping ? 'ai_res_blink ai_table_style' : 'ai_table_style'"
        v-html="ai_table"
      ></div>
      <div
        v-show="show_res && ai_form.select === 'generateDiagram'"
        class="mermaid-wrap"
      >
        <div>
          <el-input
            ref="ai_aiCode2"
            v-model="ai_form.aiCode"
            type="textarea"
            :rows="20"
            :placeholder="$t('node.ai.placeholder1')"
            style="margin-bottom: 20px"
            maxlength="5000"
            show-word-limit
          >
          </el-input>
        </div>
        <div v-if="ai_form.aiCode" @click="zoomMermaid">
          <vue-mermaid-string id="svgdom" :value="ai_form.aiCode" />
        </div>
      </div>
      <div
        v-show="show_res && !robotTyping"
        slot="footer"
        class="dialog-footer"
      >
        <el-button type="info" @click="bumanyi_click">{{
          $t("knowledge.knowledge_ai.btn2")
        }}</el-button>
        <el-button type="primary" class="button-confirm" @click="manyi_click">{{
          $t("knowledge.knowledge_ai.btn3")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="zoomMermaidVisible"
      :close-on-click-modal="true"
      width="60%"
      :title="$t('templateLibrary.preview')"
    >
      <div v-if="zoomMermaidVisible">
        <vue-mermaid-string id="svgdom" :value="ai_form.aiCode" />
      </div>
    </el-dialog>
    <payDialog
      :dialog-visible.sync="payDialog_isShow"
      :title_text="$t('AI.tip6')"
      :tip_text="$t('AI.tip7')"
    ></payDialog>
    <mavon-editor
      ref="ai_md"
      v-model="ai_markdown_value"
      style="display: none"
      @change="ai_markdown_value_change"
    >
    </mavon-editor>

    <mavon-editor
      ref="ai_md"
      v-model="ai_last_value"
      style="display: none"
      @change="ai_markdown_last_value_change"
    >
    </mavon-editor>
  </div>
</template>

<script>
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { mapGetters } from "vuex";
import drawio from "./drawio.vue";
import markdownEditor from "@/components/markdownEditor";
import { ulid } from "ulid";
import { given_content_pic } from "@/network/ai/index.js";
import { download_knowledge_drawio } from "@/network/fileOperation/index.js";
import { aiRobotPPayFeatureStatus } from "@/network/ai/index.js";
import TurndownService from "turndown";
import payDialog from "@/components/payFeatureValidDialog";
import emojiData from "./emoji.js";
export default {
  components: {
    drawio,
    markdownEditor,
    payDialog,
  },
  props: {
    quill: {
      type: Object,
      default() {
        return null;
      },
    },
    container: {
      type: String,
      default: "",
    },
    client: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      sizeList: [
        {
          value: "",
          label: "normal",
        },
        {
          value: "10px",
          label: "10px",
        },
        {
          value: "12px",
          label: "12px",
        },
        {
          value: "14px",
          label: "14px",
        },
        {
          value: "16px",
          label: "16px",
          selected: true,
        },
        {
          value: "18px",
          label: "18px",
        },
        {
          value: "20px",
          label: "20px",
        },
        {
          value: "22px",
          label: "22px",
        },
        {
          value: "24px",
          label: "24px",
        },
        {
          value: "26px",
          label: "26px",
        },
        {
          value: "32px",
          label: "32px",
        },
        {
          value: "48px",
          label: "48px",
        },
      ],
      showTableList: false,
      mouseRowCol: {
        row: 0,
        col: 0,
      },
      formatObject: {},
      // toolbar标题
      titleConfig: [
        { Choice: ".icon-go-back", title: this.$t("knowledge.Undo") },
        { Choice: ".icon-go-forward", title: this.$t("knowledge.Redo") },
        { Choice: ".icon-format", title: this.$t("knowledge.Format") },
        { Choice: ".ql-insertMetric", title: "跳转配置" },
        { Choice: ".ql-bold", title: this.$t("knowledge.Bold") },
        { Choice: ".ql-italic", title: this.$t("knowledge.Italic") },
        { Choice: ".ql-underline", title: this.$t("knowledge.Underline") },
        { Choice: ".ql-header", title: this.$t("knowledge.ParagraphFormat") },
        { Choice: ".ql-strike", title: this.$t("knowledge.Strikethrough") },
        { Choice: ".ql-blockquote", title: this.$t("knowledge.Blockquote") },
        { Choice: ".ql-code", title: this.$t("knowledge.InsertCode") },
        {
          Choice: ".ql-code-block",
          title: this.$t("knowledge.InsertCodeBlock"),
        },
        { Choice: ".ql-font", title: this.$t("knowledge.Font") },
        { Choice: ".ql-size", title: this.$t("knowledge.FontSize") },
        {
          Choice: '.ql-list[value="ordered"]',
          title: this.$t("knowledge.NumberedList"),
        },
        {
          Choice: '.ql-list[value="bullet"]',
          title: this.$t("knowledge.BulletedList"),
        },
        { Choice: ".ql-direction", title: this.$t("knowledge.TextDirectio") },
        { Choice: '.ql-header[value="1"]', title: "h1" },
        { Choice: '.ql-header[value="2"]', title: "h2" },
        { Choice: ".ql-align", title: this.$t("knowledge.Alignment") },
        { Choice: ".ql-color", title: this.$t("knowledge.FontColor") },
        {
          Choice: ".ql-background",
          title: this.$t("knowledge.BackgroundColor"),
        },
        { Choice: ".ql-image", title: this.$t("knowledge.Image") },
        { Choice: ".ql-video", title: this.$t("knowledge.Video") },
        { Choice: ".ql-link", title: this.$t("knowledge.AddLink") },
        { Choice: ".ql-formula", title: this.$t("knowledge.InsertFormula") },
        {
          Choice: ".ql-clean",
          title: this.$t("knowledge.ClearFontFormatting"),
        },
        { Choice: ".ql-better-table", title: this.$t("knowledge.InsertTable") },
        {
          Choice: '.ql-script[value="sub"]',
          title: this.$t("knowledge.Subscript"),
        },
        {
          Choice: '.ql-script[value="super"]',
          title: this.$t("knowledge.Superscript"),
        },
        {
          Choice: '.ql-indent[value="-1"]',
          title: this.$t("knowledge.DecreaseIndent"),
        },
        {
          Choice: '.ql-indent[value="+1"]',
          title: this.$t("knowledge.IncreaseIndent"),
        },
        {
          Choice: ".ql-header .ql-picker-label",
          title: this.$t("knowledge.HeadingSize"),
        },
        {
          Choice: '.ql-header .ql-picker-item[data-value="1"]',
          title: "标题一",
        },
        {
          Choice: '.ql-header .ql-picker-item[data-value="2"]',
          title: "标题二",
        },
        {
          Choice: '.ql-header .ql-picker-item[data-value="3"]',
          title: "标题三",
        },
        {
          Choice: '.ql-header .ql-picker-item[data-value="4"]',
          title: "标题四",
        },
        {
          Choice: '.ql-header .ql-picker-item[data-value="5"]',
          title: "标题五",
        },
        {
          Choice: '.ql-header .ql-picker-item[data-value="6"]',
          title: "标题六",
        },
        { Choice: ".ql-header .ql-picker-item:last-child", title: "标准" },
        {
          Choice: '.ql-size .ql-picker-item[data-value="small"]',
          title: "小号",
        },
        {
          Choice: '.ql-size .ql-picker-item[data-value="large"]',
          title: "大号",
        },
        {
          Choice: '.ql-size .ql-picker-item[data-value="huge"]',
          title: "超大号",
        },
        { Choice: ".ql-size .ql-picker-item:nth-child(2)", title: "标准" },
        { Choice: ".ql-align .ql-picker-item:first-child", title: "居左对齐" },
        {
          Choice: '.ql-align .ql-picker-item[data-value="center"]',
          title: "居中对齐",
        },
        {
          Choice: '.ql-align .ql-picker-item[data-value="right"]',
          title: "居右对齐",
        },
        {
          Choice: '.ql-align .ql-picker-item[data-value="justify"]',
          title: "两端对齐",
        },
        {
          Choice: ".icon-highlight",
          title: this.$t("knowledge.HighlightBlock"),
        },
        {
          Choice: ".ql-drawio",
          title: this.$t("nodeDetail.basicInfo.structureDiagram"),
        },
        {
          Choice: ".ql-markdown",
          title: "markdown",
        },
        {
          Choice: ".ql-emoji",
          title: "emoji",
        },
        {
          Choice: ".ql-ai",
          title: "ai",
        },
      ],
      imgDom: null,
      // markdown弹窗
      markdownVisible: false,
      showMark: false,
      lastSelection: null,
      // ai
      ai_select: false,
      ai_file: false,
      ai_show: false,
      ai_form: {
        select: "",
        lang: "",
        ai_text: "",
        aiSelect: "",
        aiCode: "",
      },

      optimize: {
        GIVEN_CODE_CREATE: "GENERATE_CODE_OPTIMIZE",
        TESTCASE_OUTLINE_CREATE: "TESTCASE_OUTLINE_OPTIMIZE",
        GIVEN_CONTENT_EXPAND: "OPTIMIZE_TEXT_CONTENT",
        GIVEN_CONTENT_POLISH: "OPTIMIZE_TEXT_CONTENT",
        GIVEN_CONTENT_SUMMARIZE: "OPTIMIZE_TEXT_CONTENT",
      },
      ai_file_list: [],
      rules: {
        select: [
          {
            required: true,
            message: this.$t("knowledge.knowledge_ai.message1"),
            trigger: "blur",
          },
        ],
      },
      show_res: false,
      bumanyi_show: false,
      bumanyi2_show: false,
      bumanyi_form: {
        a: "",
        b: "",
        c: "",
        d: "",
      },
      tab1Ulid: "",
      ai_res: "",
      ai_res_origin: "",
      ai_table: "",
      robotTyping: false,
      ai_markdown_value: "",
      is_table: false,
      controller: "",
      right_tip: null,
      ai_last_value: "",
      ai_final: "",
      aiOps: [
        {
          label: this.$t("node.ai.aiDiagram1"),
          value: "SEQUENCE_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram2"),
          value: "FLOW_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram3"),
          value: "CLASS_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram4"),
          value: "STATE_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram5"),
          value: "ER_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram6"),
          value: "GANTT_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram7"),
          value: "GIT_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram8"),
          value: "MIND_MAP_DIAGRAM",
        },
      ],
      zoomMermaidVisible: false,
      payDialog_isShow: false,
      emojiData: emojiData,
    };
  },
  computed: {
    ...mapGetters(["token"]),
    languageType() {
      let type = "";
      const lang = localStorage.getItem("lang");
      if (lang === "en") {
        type = "en-US";
      } else if (lang === "zh") {
        type = "zh-CN";
      }
      return type;
    },
    ai_ops() {
      let d = [];

      if (this.is_table) {
        d = [
          {
            label: this.$t("knowledge.knowledge_ai.op3"),
            value: "GIVEN_CONTENT_PIC",
          },
        ];
      } else {
        d = [
          {
            label: this.$t("knowledge.knowledge_ai.op1"),
            value: "GIVEN_CONTENT_EXPAND",
          },
          {
            label: this.$t("knowledge.knowledge_ai.op2"),
            value: "GIVEN_CONTENT_POLISH",
          },
          {
            label: this.$t("knowledge.knowledge_ai.op3"),
            value: "GIVEN_CONTENT_PIC",
          },
          {
            label: this.$t("knowledge.knowledge_ai.op4"),
            value: "GIVEN_CONTENT_SUMMARIZE",
          },
          {
            label: this.$t("knowledge.knowledge_ai.op5"),
            value: "TESTCASE_OUTLINE_CREATE",
          },
          {
            label: this.$t("knowledge.knowledge_ai.op6"),
            value: "GIVEN_CODE_CREATE",
          },
          {
            label: this.$t("knowledge.knowledge_ai.op7"),
            value: "generateDiagram",
          },
        ];
      }

      return d;
    },
  },
  watch: {
    quill(val) {
      if (val) {
        this.quill.container.addEventListener(
          "dblclick",
          this.editDrawio,
          true
        );
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.hideTableListFn);
    // 设置toolbar的popup标题
    for (let item of this.titleConfig) {
      let tip = document.querySelector(item.Choice);
      if (!tip) continue;
      tip.setAttribute("title", item.title);
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideTableListFn);
    this.quill.container.removeEventListener("dblclick", this.editDrawio);
  },
  methods: {
    // 放大架构图
    zoomMermaid() {
      this.zoomMermaidVisible = true;
    },
    submit_btn() {
      if (this.ai_form.select == "GIVEN_CODE_CREATE") {
        if (
          this.ai_form.select.length < 1 ||
          this.ai_form.ai_text.length < 1 ||
          this.ai_form.lang.length < 1
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.ai_form.select == "generateDiagram") {
        if (
          this.ai_form.select.length < 1 ||
          this.ai_form.ai_text.length < 1 ||
          this.ai_form.aiSelect.length < 1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.ai_form.select.length < 1 || this.ai_form.ai_text.length < 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    ai_markdown_last_value_change(a, b) {
      this.right_tip = this.$notify({
        title: this.$t("knowledge.knowledge_ai.title4"),
        dangerouslyUseHTMLString: true,
        message: b,
        showClose: false,
        customClass: "ai_res_content",
        duration: 0,
      });
    },
    before_bumanyi() {
      this.right_tip.close();
    },
    insert_img(index, url) {
      this.quill.insertEmbed(index, "image", {
        src: url,
        drawio: false,
        width: "300",
      });
    },
    ai_markdown_value_change(a, b) {
      setTimeout(() => {
        this.ai_final = b;
        if (this.bumanyi_show || this.ai_show) {
          this.ai_table = b;
          const divs = document.getElementById("ai_table");
          if (divs) {
            divs.scrollTop = divs.scrollHeight;
          }
          const divs2 = document.getElementById("ai_table2");

          if (divs2) {
            divs2.scrollTop = divs2.scrollHeight;
          }
        }
      }, 500);
    },
    bumanyi_shengcheng() {
      let msgg = this.$t("knowledge.knowledge_ai.message3");
      if (this.bumanyi_show) {
        if (this.bumanyi_form.b.length < 1) {
          this.$message.warning(msgg);
          return;
        }
        let content = "";

        if (this.languageType == "en-US") {
          if (this.bumanyi_form.a.length > 1) {
            content += `As a ${this.bumanyi_form.a},`;
          }
          if (this.bumanyi_form.b.length > 1) {
            content += `I hope to ${this.bumanyi_form.b} the above content`;
          }
          if (this.bumanyi_form.c.length > 1) {
            content += `,To achieve the goal of ${this.bumanyi_form.c}`;
          }
          if (this.bumanyi_form.d.length > 1) {
            content += `.In Addition, I Have Another Requirement: ${this.bumanyi_form.d}.`;
          }
        } else {
          if (this.bumanyi_form.a.length > 1) {
            content += `作为一名${this.bumanyi_form.a}，`;
          }
          if (this.bumanyi_form.b.length > 1) {
            content += `我希望对上述内容进行${this.bumanyi_form.b}`;
          }
          if (this.bumanyi_form.c.length > 1) {
            content += `，以达成${this.bumanyi_form.c}目的。`;
          }
          if (this.bumanyi_form.d.length > 1) {
            content += `除此之外，我还有一项要求：${this.bumanyi_form.d}`;
          }
        }

        let self = this;
        self.robotTyping = true;
        let lastRobotMessage = this.ai_res_origin;
        this.ai_res_origin = "";
        this.ai_res = "";
        this.ai_table = "";
        this.show_res = true;
        let controller = new AbortController();
        const signal = controller.signal;
        if (this.ai_form.select === "generateDiagram") {
          this.ai_form.aiCode = "";
        }
        fetchEventSource(
          `${self.wsurl}${
            self.wsurl ? "" : "/prod-api"
          }/file-manage-service/${self.get_pid()}/aiRobot/optimize`,
          {
            method: "POST",
            signal: signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: self.token,
              "Accept-Language": self.languageType,
            },
            body: JSON.stringify({
              sessionId: this.tab1Ulid,
              content: content,
              operate:
                this.ai_form.select === "generateDiagram"
                  ? "OPTIMIZE_TEXT_CONTENT"
                  : this.optimize[this.ai_form.select],
              lastRobotMessage: lastRobotMessage,
            }),
            openWhenHidden: true,
            onmessage(event) {
              self.robotTyping = true;
              if (self.ai_form.select === "generateDiagram") {
                const data = JSON.parse(event.data);
                self.ai_form.aiCode += data.value.replaceAll(
                  "\\n",
                  `
  `
                );
                self.ai_res_origin = self.ai_form.aiCode;
              } else {
                const data = JSON.parse(event.data);
                if (data.value) {
                  self.ai_res_origin += data.value;
                  self.ai_res += self.modify_text(data.value);
                  self.ai_markdown_value = self.modify_enter(self.ai_res);
                }
              }

              if (!self.bumanyi_show) {
                controller.abort();
                self.robotTyping = false;
              }
            },
            onerror(err) {
              self.robotTyping = false;
              throw err;
            },
            onclose() {
              self.robotTyping = false;
            },
          }
        );
      }
    },
    bumanyi_click() {
      //     if (!this.right_tip) {
      //       this.ai_last_value = this.ai_res.replaceAll(
      //         "</br>",
      //         `
      // `
      //       );
      //     }
      if (this.right_tip) {
        this.right_tip.close();
      }
      this.ai_last_value =
        this.ai_form.select === "generateDiagram"
          ? this.ai_form.aiCode
          : this.modify_enter(this.ai_res);
      this.ai_show = false;
      this.ai_select = false;
      this.ai_file = false;
      this.show_res = false;
      this.bumanyi_form = {
        a: "",
        b: "",
        c: "",
        d: "",
      };
      if (!this.bumanyi_show) {
        this.bumanyi_show = true;
        this.bumanyi2_show = false;
      } else {
        this.bumanyi_show = true;
        this.bumanyi2_show = true;
      }
    },
    manyi_click() {
      if (this.ai_form.select == "GIVEN_CONTENT_PIC") {
        let ind = 0;
        if (this.lastSelection) {
          if (this.lastSelection.length) {
            ind = this.lastSelection.index + this.lastSelection.length;
          } else {
            ind = this.lastSelection.index;
          }
        }
        this.insert_img(ind, this.ai_res_origin);
      } else if (this.ai_form.select == "generateDiagram") {
        const dom = this.bumanyi_show
          ? this.$refs.ai_aiCode2
          : this.$refs.ai_aiCode;
        console.log(dom);
        dom.select(); // 选取文本域内容;
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
        // Input要在正常的编辑状态下原生复制方法才会生效
        document.execCommand("Copy");
        setTimeout(() => {
          this.$notify({
            title: this.$t("node.ai.alertTitle"),
            message: this.$t("node.ai.alertMessage"),
            duration: 0,
            customClass: "ai-mermaid-alert",
          });
          this.$refs.drawio.add_draw();
        });
      } else {
        let ind = 0;
        if (this.lastSelection) {
          if (this.lastSelection.length) {
            ind = this.lastSelection.index + this.lastSelection.length;
          } else {
            ind = this.lastSelection.index;
          }
        }
        if (this.is_table) {
          this.quill.clipboard.dangerouslyPasteHTML(ind, this.ai_final);
        } else {
          this.quill.clipboard.dangerouslyPasteHTML(
            ind,
            "<br>" + this.ai_final + "<br>"
          );

          const divs = document.getElementById("editor-container-wrap");
          if (divs) {
            divs.scrollTop = this.quill.getBounds(ind).top;
          }
          this.quill.setSelection(ind, 0);
        }
      }
      this.ai_form.select = "";
      this.ai_form.lang = "";
      this.ai_form.ai_text = "";
      this.ai_form.aiCode = "";
      this.ai_form.aiSelect = "";
      this.ai_res = "";
      this.ai_res_origin = "";
      this.ai_table = "";

      this.$refs["ai-form"].clearValidate();

      this.ai_show = false;
      this.ai_select = false;
      this.ai_file = false;
      this.show_res = false;
      this.bumanyi_show = false;
      this.bumanyi2_show = false;
    },
    submitForm(formName) {
      if (this.ai_form.ai_text.length < 1) {
        this.$message.warning(this.$t("knowledge.knowledge_ai.placeholder1"));
        if (this.$refs["ai_adilog2"]) {
          this.$refs["ai_adilog2"].focus();
        }
        if (this.$refs["ai_adilog1"]) {
          this.$refs["ai_adilog1"].focus();
        }
        return;
      }
      let self = this;
      this.$refs["ai-form"].validate((valid) => {
        if (valid) {
          self.robotTyping = true;
          this.show_res = true;
          this.ai_res = "";
          this.ai_table = "";
          this.ai_res_origin = "";
          this.tab1Ulid = ulid();
          if (this.ai_form.select == "GIVEN_CODE_CREATE") {
            let controller = new AbortController();
            const signal = controller.signal;

            fetchEventSource(
              `${self.wsurl}${
                self.wsurl ? "" : "/prod-api"
              }/file-manage-service/${self.get_pid()}/aiRobot/generateCode`,
              {
                method: "POST",
                signal: signal,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: self.token,
                  "Accept-Language": self.languageType,
                },
                body: JSON.stringify({
                  sessionId: self.tab1Ulid,
                  content: this.ai_form.ai_text,
                  lang: self.ai_form.lang,
                }),
                openWhenHidden: true,
                onmessage(event) {
                  self.robotTyping = true;
                  const data = JSON.parse(event.data);
                  if (data.value) {
                    const data = JSON.parse(event.data);
                    if (data.value) {
                      self.ai_res_origin += data.value;
                      self.ai_res += self.modify_text(data.value);
                      self.ai_markdown_value = self.modify_enter(self.ai_res);
                    }
                  }
                  if (!self.ai_show) {
                    controller.abort();
                    self.robotTyping = false;
                  }
                },
                onerror(err) {
                  self.robotTyping = false;
                  throw err;
                },
                onclose() {
                  self.robotTyping = false;
                },
              }
            );
          } else if (this.ai_form.select == "GIVEN_CONTENT_PIC") {
            self.robotTyping = true;
            given_content_pic(this.get_pid(), {
              sessionId: this.tab1Ulid,
              content: this.ai_form.ai_text,
            }).then((res) => {
              self.robotTyping = false;
              self.ai_res = `<img src="${res}" style="width:100%">`;
              self.ai_res_origin = res;
            });
          } else if (this.ai_form.select == "generateDiagram") {
            const self = this;
            aiRobotPPayFeatureStatus().then((res) => {
              if (res) {
                let controller = new AbortController();
                const signal = controller.signal;
                // 清空代码区域
                this.ai_form.aiCode = "";
                fetchEventSource(
                  `${self.wsurl}${
                    self.wsurl ? "" : "/prod-api"
                  }/file-manage-service/${self.get_pid()}/aiRobot/generateDiagram`,
                  {
                    method: "POST",
                    signal: signal,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: self.token,
                      "Accept-Language": self.languageType,
                    },
                    body: JSON.stringify({
                      sessionId: ulid(),
                      content: self.ai_form.ai_text,
                      operate: "CREATE_DIAGRAM",
                      diagramTypes: self.ai_form.aiSelect,
                    }),
                    openWhenHidden: true,
                    onmessage(event) {
                      const data = JSON.parse(event.data);
                      self.ai_form.aiCode += data.value
                        ? data.value.replaceAll(
                            "\\n",
                            `
  `
                          )
                        : "";
                    },
                    onerror(err) {
                      self.robotTyping = false;
                      throw err;
                    },
                    onclose() {
                      let regex = /```mermaid([^`]*?)```/g;
                      let matches = self.ai_form.aiCode.match(regex);
                      self.ai_form.aiCode =
                        matches && matches[0]
                          ? matches[0].replace(/```mermaid|```/g, "").trim()
                          : self.ai_form.aiCode.trim();
                      self.robotTyping = false;
                      self.ai_res_origin = self.ai_form.aiCode;
                    },
                  }
                );
              } else {
                this.payDialog_isShow = true;
              }
            });
          } else {
            let controller = new AbortController();
            const signal = controller.signal;
            fetchEventSource(
              `${this.wsurl}${
                this.wsurl ? "" : "/prod-api"
              }/file-manage-service/${this.get_pid()}/aiRobot/create`,
              {
                method: "POST",
                signal: signal,

                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                  "Accept-Language": this.languageType,
                },
                body: JSON.stringify({
                  sessionId: this.tab1Ulid,
                  content: this.ai_form.ai_text,
                  operate: this.ai_form.select,
                }),
                openWhenHidden: true,
                onmessage(event) {
                  self.robotTyping = true;
                  const data = JSON.parse(event.data);
                  if (data.value) {
                    self.ai_res_origin += data.value;
                    self.ai_res += self.modify_text(data.value);
                    self.ai_markdown_value = self.modify_enter(self.ai_res);
                  }
                  if (!self.ai_show) {
                    controller.abort();
                    self.robotTyping = false;
                  }
                },
                onerror(err) {
                  self.robotTyping = false;
                  throw err;
                },
                onclose() {
                  self.robotTyping = false;
                },
              }
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 展示AI弹窗
    showAi() {
      this.is_table = false;
      if (this.quill.getModule("better-table").tableSelection) {
        this.is_table = true;
      }
      this.lastSelection = this.quill.getSelection();
      this.robotTyping = false;
      if (this.lastSelection && this.lastSelection.length > 0) {
        this.ai_select = true;
        this.ai_file = false;
        this.ai_show = true;

        this.ai_form.ai_text = this.quill
          .getText(this.lastSelection.index, this.lastSelection.length)
          .substr(0, 1000);

        this.show_res = false;
        this.ai_form.select = "";
        this.ai_form.lang = "";
        this.ai_res = "";
        this.ai_res_origin = "";
        this.ai_table = "";
        this.$nextTick(() => {
          this.$refs["ai-form"].clearValidate();
        });
      } else {
        this.ai_form.ai_text = "";
        this.ai_select = false;
        this.ai_file = true;
        this.ai_show = true;
        this.show_res = false;
        this.ai_form.select = "";
        this.ai_form.lang = "";
        this.ai_res = "";
        this.ai_table = "";
        this.ai_res_origin = "";
        this.$nextTick(() => {
          this.$refs["ai-form"].clearValidate();
        });
      }
    },
    modify_enter(text) {
      return text.replaceAll(
        "</br>",
        `
  `
      );
    },
    modify_text(text) {
      text = text.replace("&lt;", /</g);
      text = text.replace("&gt;", />/g);
      text = text.replaceAll("\\n", "</br>");
      text = text.replaceAll("&ensp;", " ");
      return text;
    },
    // 展示markdown弹窗
    showMarkDown() {
      this.lastSelection = this.quill.getSelection();
      // 通过Quill 把选中内容 整个插入
      let html = "";
      let markdown = "";
      if (this.lastSelection && this.lastSelection.length) {
        const contents = this.quill.getLines(
          this.lastSelection.index,
          this.lastSelection.length
        );
        contents.forEach((item) => {
          html += item.domNode.outerHTML;
        });
        const turndownService = new TurndownService({
          headingStyle: "atx",
        });
        markdown = turndownService.turndown(html);
      }
      this.markdownVisible = true;
      this.showMark = true;
      this.$nextTick(() => {
        this.$refs.md.value = markdown;
      });
    },
    // 添加emoji
    showEmoji(emoji) {
      const range = this.quill.getSelection(true);
      if (range) {
        this.quill.insertEmbed(range.index, "emoji", emoji, "user");
        this.quill.setSelection(range.index + 2, 0);
      }
    },
    // 确认插入
    markdownDialogConfirm() {
      let index = 0;
      if (this.lastSelection && this.lastSelection.length) {
        let [startLine, startOffset] = this.quill.getLine(
          this.lastSelection.index
        );
        let [endLine, endOffset] = this.quill.getLine(
          this.lastSelection.index + this.lastSelection.length - 1
        );

        // 获取起始行的起始位置
        let startIndex = this.quill.getIndex(startLine);
        // 获取结束行的结束位置
        let endIndex = this.quill.getIndex(endLine) + endLine.length();
        this.quill.deleteText(startIndex, endIndex - startIndex);
        index = startIndex;
      } else {
        index = this.lastSelection ? this.lastSelection.index : 0;
      }
      this.quill.clipboard.dangerouslyPasteHTML(
        index,
        this.$refs.md.$refs.md.d_render
      );
      this.markdownVisible = false;
    },
    // quill 自定义方法
    undo() {
      this.quill.history.undo();
    },
    redo() {
      this.quill.history.redo();
    },
    format() {
      if (JSON.stringify(this.formatObject) !== "{}") {
        for (let item in this.formatObject) {
          this.quill.format(item, this.formatObject[item]);
        }
        this.formatObject = {};
      } else {
        this.formatObject = this.quill.getFormat();
      }
    },
    add_link() {
      if (!this.quill.getSelection()) {
        this.$message.warning(this.$t("knowledge.SelectText"));
        return;
      }

      if (this.quill.getSelection().length < 1) {
        this.$message.warning(this.$t("knowledge.SelectText"));
        return;
      }
      const range = this.quill.getSelection();
      const text = this.quill.getText(range.index, range.length);
      console.log(text);

      this.quill.deleteText(range.index, range.length);
      this.quill.insertEmbed(range.index, "my-link", {
        name: text,
        link: text,
        type: "link",
      });
    },
    // table下拉框显示
    showTableListFn() {
      this.showTableList = true;
    },
    // 插入高亮块
    insertLightBlock() {
      const range = this.quill.getSelection();
      if (range.length) {
        const text = this.quill.getText(range.index, range.length);
        this.quill.deleteText(range.index, range.length);
        this.quill.insertEmbed(range.index, "lightBlock", true, "silent");
        this.quill.insertText(range.index, text, "silent");
      } else {
        this.quill.insertEmbed(range.index, "lightBlock", true, "silent");
        this.quill.setSelection(range.index, 0);
      }
    },
    hideTableListFn() {
      this.showTableList = false;
    },
    tableListMove(row, col) {
      this.mouseRowCol.row = row;
      this.mouseRowCol.col = col;
    },
    tableListLeave() {
      this.mouseRowCol.row = 0;
      this.mouseRowCol.col = 0;
    },
    fileToDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    insertTable() {
      const range = this.quill.getSelection();
      if (range) {
        this.quill.setSelection(range.index, range.length);
        this.quill
          .getModule("better-table")
          .insertTable(this.mouseRowCol.row, this.mouseRowCol.col);
        this.showTableList = false;
      } else {
        this.$message({
          type: "info",
          message: this.$t("knowledge.PleaseConfirmTheLocation"),
        });
      }
    },
    async editDrawio(event) {
      const mouseElements = document.elementsFromPoint(
        event.clientX,
        event.clientY
      );
      if (
        mouseElements[1] &&
        mouseElements[1].nodeName === "IMG" &&
        mouseElements[1].getAttribute("drawio")
      ) {
        let res = await download_knowledge_drawio(
          mouseElements[1].src + "?id=" + ulid()
        );
        const transToFile = async (res) => {
          return new window.File([res], "drawio", { type: "image/png" });
        };
        let textContain = transToFile(res);
        textContain.then((res) => {
          this.fileToDataURL(res).then((str) => {
            this.$refs["drawio"].add_draw(str);
            this.imgDom = mouseElements[1];
          });
        });
      }
    },
    clearImgDom() {
      this.imgDom = null;
    },
  },
};
</script>
<style lang="scss">
.ai-mermaid-alert {
  z-index: 1000000 !important;
}
.ai_res_blink:after {
  -webkit-animation: blink 1s steps(2, start) infinite;
  animation: blink 1s steps(2, start) infinite;
  content: "▋";
  margin-left: 1px;
  vertical-align: baseline;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ai_table_style {
  padding: 10px;
  margin-top: 20px;
  border: 2px solid #a6a6a6;
  overflow-y: auto;
  border-radius: 4px;
  height: 300px;
  table {
    border: 1px solid;
    border-spacing: 0;
    td {
      border: 1px solid;
    }
    th {
      border: 1px solid;
    }
  }
}
.ai_res_content {
  z-index: 999999 !important;
  top: 15vh !important;
  padding: 14px 14px 14px 13px !important;
  .el-notification__group {
    width: calc(100%);
    margin: 0 !important;
  }
  .el-notification__content {
    max-height: 400px;
    padding: 0 5px;
    overflow-y: auto;
    table {
      border-spacing: 0;
      border: 1px solid;
      td {
        border: 1px solid;
      }
      th {
        border: 1px solid;
      }
    }
  }
}
.ai-form {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}
.ai_bumanyi {
  .el-alert__title {
    color: #383838;
  }
}
</style>
<style lang="scss" scoped>
.mermaid-wrap {
  display: flex;
  margin-top: 20px;
  background-color: #eee;
  padding: 6px;
  & > div {
    width: 50%;
  }
}
.ql-editor {
  height: fit-content;
}
.ql-font,
.ql-size {
  margin-right: 10px;
}
.ql-font,
.ql-size,
.ql-lineHeight {
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 2px;
}
.ql-video {
  width: 1027px;
  height: 624px;
}
.table-icon {
  font-size: 18px;
  /*no*/
}
.table-list {
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 9;
  border: 2px solid #dee0e3;
  & > div {
    display: flex;
    & > div {
      width: 20px;
      height: 20px;
      background-color: #f2f3f5;
      border: 2px solid #eff0f1;
      margin: 4px;
    }
    .list-back-color {
      background-color: rgb(130, 167, 255);
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: right;
}

.dialog-footer .button-confirm {
  background-color: rgba(1, 76, 170, 1);
  color: #f8fafc;
}

.dialog-footer .button-confirm:hover {
  background-color: #5596cb;
}
.ai_select_text_style {
  height: 150px;
  background-color: #dcf3ce;
  border: 2px solid #86c166;
  overflow-y: auto;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
}
.ai_res_style {
  padding: 10px;
  margin-top: 20px;
  border: 2px solid #a6a6a6;
  overflow-y: auto;
  border-radius: 4px;
  height: 300px;
}
.emojiPo {
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  overflow-y: auto;
}
</style>

<template>
  <div v-if="isLocale === 'true'" class="language-set">
    <el-dropdown size="medium" placement="bottom" @command="handleClickLocale">
      <i
        class="iconfont icon-locale"
        style="cursor: pointer; font-size: 20px; color: #fff"
      ></i>
      <el-dropdown-menu
        slot="dropdown"
        style="margin-top: 0px"
        class="menu-item"
      >
        <el-dropdown-item command="zh">简体中文</el-dropdown-item>
        <el-dropdown-item command="en" divided>English </el-dropdown-item>
        <!--                <el-dropdown-item command="de" divided>Deutsch</el-dropdown-item>-->
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    isLocale() {
      return sessionStorage.getItem("isLocale") || "false";
    },
  },
  created() {
    sessionStorage.setItem("isLocale", "true");
  },
  methods: {
    handleClickLocale(command) {
      localStorage.setItem("lang", command);
      window.location.reload();
    },
  },
};
</script>

<style></style>

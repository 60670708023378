<template>
  <div
    v-show="wrapShow"
    v-loading="loading"
    id="edit-wrap"
    class="edit-wrap"
    @click.prevent="clickeWrap"
  >
    <div id="standalone-container">
      <toolbar
        :quill="quill"
        :client="client"
        container="toolbar-container"
      ></toolbar>
      <div id="editor-container-wrap" @scroll="warpScroll">
        <div
          v-show="connected"
          id="editor-container"
          class="editor-container"
          @click="checktext"
          @keydown="handleTextChange"
        ></div>
      </div>
      <span
        v-for="(cursor, index) in userCursorList"
        v-show="cursor.show"
        :key="index"
        class="cursor_span"
        :style="{
          top: cursor.top + 'px',
          left: cursor.left + 'px',
          'background-color': cursor.color,
          color: cursor.color,
        }"
        :class="{
          twinkle: cursor.animation,
        }"
      >
        <i
          :style="{ 'background-color': cursor.color }"
          @mouseenter="
            cursor.showName = true;
            cursor.animation = false;
          "
          @mouseout="
            cursor.showName = false;
            cursor.animation = true;
          "
        ></i>
        <span v-if="cursor.showName">{{ cursor.userName }}</span>
      </span>
    </div>
    <div id="menu">
      <div>
        <div
          v-for="(item, index) in rightMenu"
          :key="index"
          @click="menuClick(index)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
    <div class="goTop">
      <i class="iconfont icon-a-huaban18" @click="goTop"></i>
    </div>
  </div>
</template>
<script>
import { ulid } from "ulid";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import { mapGetters } from "vuex";
import { editDocumentUnlogin } from "@/network/knowledge";
import { unloginOssAuthorization } from "@/utils/oss";
// import QuillBetterTable from "@/plugins/quill-better-table.js";
import { customHeader } from "@/plugins/quill.custom.js";
import toolbar from "@/views/knowledge/components/toolbar.vue";
export default {
  components: {
    toolbar,
  },
  props: {
    documentInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    content: {
      type: String,
      default: "",
    },
    internetToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rightMenu: [
        {
          label: this.$t("knowledge.GenerateSharingLink"),
        },
      ],
      isPublic: false,
      quill: null,
      formatObject: {},
      connected: false,
      userCursorList: [],
      upload_list: [],
      timer: null,
      loading: false,
      atShow: false,
      wrapShow: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      userAccountId: "",
      saveTimer: null,
      // 存入每次修改的ops
      opsList: [],
      // 记录scrollTop
      wrapScrollTop: 0,
      client: null,
    };
  },
  computed: {
    ...mapGetters(["token", "user_list"]),
  },
  created() {},
  async mounted() {
    await unloginOssAuthorization.call(this, {
      pathId: this.$route.params.pathId,
      token: this.internetToken,
    });
    customHeader(this.client);
    // 初始化quill
    this.initQuill();
    this.quill.on("text-change", this.text_change);
    this.quill.on("selection-change", this.selection_change);
    // 建立ws链接
    this.initWebscoket();
    // 创建右键菜单
    this.initRightClick();
    this.userAccountId = ulid();
    const goTop = document.getElementsByClassName("goTop")[0];
    goTop.style.display = "none";
  },
  beforeDestroy() {
    this.quill = null;
    this.closeWebsocket();
  },
  methods: {
    checktext(event) {
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;

      if (
        selection.toString() != "" &&
        (commonAncestor.id == undefined || commonAncestor.id != "")
      ) {
        this.setDownposition();
        event.stopPropagation();
      }
    },
    initRightClick() {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("editor-container");
      const wrap2 = document.getElementById("edit-wrap");
      wrap.oncontextmenu = function (evt) {
        self.setDownposition(evt);
      };
      wrap2.onclick = function () {
        self.lineId && document.getElementById(self.lineId)
          ? document
              .getElementById(self.lineId)
              .classList.remove("knowledge-selected-Line")
          : "";
        menu.style.display = "none";
      };
    },
    setDownposition(evt) {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("editor-container");
      const toolbar = document.getElementById("toolbar-container");
      // 自定义body元素的鼠标事件处理函数
      const e = evt || window.event;
      e.preventDefault(); //阻止系统右键菜单
      self.lineId && document.getElementById(self.lineId)
        ? document
            .getElementById(self.lineId)
            .classList.remove("knowledge-selected-Line")
        : "";
      // 显示自定义的菜单调整位置
      self.lineId = e.target.id ? e.target.id : e.target.parentNode.id;
      document.getElementById(self.lineId)
        ? document
            .getElementById(self.lineId)
            .classList.add("knowledge-selected-Line")
        : "";
      if (self.lineId) {
        console.log(e, wrap, toolbar);
        console.log(e.layerY, wrap.scrollTop, toolbar.clientHeight);
        menu.style.display = "block";
        menu.style.left = e.layerX + "px";
        menu.style.top = e.pageY - 2 * toolbar.clientHeight + "px";
      }
    },
    handleTextChange(event) {
      const self = this;
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let position = this.quill.getSelection();
      let commonAncestor = range.commonAncestorContainer.parentElement;
      if (event.keyCode === 8) {
        if (
          commonAncestor.style.color == "rgb(64, 158, 255)" &&
          commonAncestor.className == "my-at"
        ) {
          const parentE = commonAncestor.parentNode;
          parentE.removeChild(commonAncestor);
          setTimeout(() => {
            self.quill.setSelection(
              position.index - commonAncestor.innerText.length,
              0
            );
          }, 0);
        }
      }
    },
    //滚动事件
    warpScroll(e) {
      this.wrapScrollTop = e.target.scrollTop;
    },
    // 关闭@
    clickeWrap() {
      this.atShow = false;
    },
    // 粘贴图片
    pasteFile(evt) {
      const html = evt.clipboardData.getData("text/html");
      const hasImg = /<img\b[^>]*>/.test(html);
      const text = evt.clipboardData.getData("text/plain");
      if (
        evt.clipboardData &&
        evt.clipboardData.files &&
        evt.clipboardData.files.length
      ) {
        [].forEach.call(evt.clipboardData.files, async (file, index) => {
          if (file.size / (1024 * 1024) >= 50) {
            this.$message({
              type: "warning",
              message: this.$t("knowledge.tooLarge"),
            });
            this.loading = false;
            return;
          }
          if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
            this.loading = true;
            const res = await this.client.put(
              `${
                this.$store.state.project.tenantId
              }/comment/${new Date().getTime()}${file.name}`,
              file
            );
            const range = this.quill.getSelection();
            if (range) {
              let length = this.quill.getSelection().index; // 获取内容长度
              this.quill.insertEmbed(length, "link", {
                href: res.url,
                innerText: file.name,
              }); // 插入链接
              const num = (file.name && file.name.length) || 0;
              this.quill.insertText(length + num, "、", true);
              this.quill.setSelection(length + num + 1); // 设置光标位置
              this.loading = false;
            }
          }
        });
      } else if (!hasImg) {
        // 判断是否是网络链接
        let re = new RegExp("^(http|https)://", "i");
        const isHttp = re.test(text);
        const index = this.quill.getSelection().index;
        if (isHttp) {
          evt.preventDefault();
          this.quill.insertEmbed(index, "link", {
            href: text,
            target: "_blank",
            innerText: text,
          });
          this.quill.setSelection(index + text.length + 1);
        } else {
          return true;
        }
      }
    },
    // 随机色
    choseRgb() {
      // Math.random是生成0-1之间的随机数 *256 的范围就变成0.xx-255.7
      // Math.floor 向下取整就变成 0-255
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      // 拼接返回
      return `rgb(${r},${g},${b})`;
    },
    initWebscoket() {
      const self = this;
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer`
      );
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          token: this.token,
          public: true,
          projectId: this.documentInfo.projectId,
          scene: "DOC_CONTENT_MODIFY",
        }, //传递token
        (frame) => {
          // 测试topic
          this.connected = true;
          this.stompClient.subscribe(
            `/topic/DOC_CONTENT_MODIFY/${this.documentInfo.projectId}/${this.documentInfo.documentId}`,
            (res) => {
              if (
                res.body &&
                JSON.parse(res.body).accountId !== this.userAccountId
              ) {
                const user = JSON.parse(res.body).accountId;
                if (JSON.parse(res.body).ops) {
                  const ops = JSON.parse(JSON.parse(res.body).ops);
                  if (ops && ops.length) {
                    ops.forEach((item) => {
                      this.quill.updateContents(item, "silent");
                    });
                  }
                }
                let has = false;
                const selection = JSON.parse(JSON.parse(res.body).selection);
                if (!selection) {
                  for (let i = 0; i < this.userCursorList.length; i++) {
                    if (
                      this.userCursorList[i].userAccountId === user &&
                      !selection
                    ) {
                      this.userCursorList.splice(i, 1);
                      this.$emit("userlistchange", this.userCursorList);
                      return;
                    }
                  }
                }
                const bounds = this.quill.getBounds(
                  selection.index,
                  selection.length
                );
                this.userCursorList.forEach((item) => {
                  if (item.userAccountId === user) {
                    has = true;
                    item.left = bounds.left + 10;
                    item.top = bounds.top + 50;
                    item.index = selection.index;
                    item.length = selection.length;
                  }
                });
                if (!has) {
                  this.userCursorList.push({
                    userName: user + "inter",
                    show: true,
                    userAccountId: user,
                    avatar: user,
                    left: bounds.left + 10,
                    top: bounds.top + 50,
                    index: selection.index,
                    length: selection.length,
                    color: this.choseRgb(),
                    showName: false,
                    animation: true,
                  });
                }
              }
              this.$emit("userlistchange", this.userCursorList);
            }
          );
        },
        (err) => {
          console.log("错误：" + err);
        }
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      this.stompClient.send(
        `/app/PUBLIC_EDIT_DOC_CONTENT/${this.$route.params.pathId}`,
        {},
        JSON.stringify({
          token: this.internetToken,
          accountId: this.userAccountId,
          selection: null,
        })
      );
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {});
        } catch (e) {
          console.log(e);
        }
      }
    },
    initQuill() {
      this.quill = new Quill("#editor-container", {
        modules: {
          toolbar: {
            container: "#toolbar-container",
          },
          history: {
            delay: 2000,
            maxStack: 500,
          },
          ImageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: this.$t("knowledge.InsertRight"),
                },
                insertColumnLeft: {
                  text: this.$t("knowledge.InsertLeft"),
                },
                insertRowUp: {
                  text: this.$t("knowledge.InsertAbove"),
                },
                insertRowDown: {
                  text: this.$t("knowledge.InsertBelow"),
                },
                mergeCells: {
                  text: this.$t("knowledge.MergeCells"),
                },
                unmergeCells: {
                  text: this.$t("knowledge.SplitCells"),
                },
                deleteColumn: {
                  text: this.$t("knowledge.DeleteColumn"),
                },
                deleteRow: {
                  text: this.$t("knowledge.DeleteRow"),
                },
                deleteTable: {
                  text: this.$t("knowledge.DeleteTable"),
                },
              },
            },
          },
          keyboard: {
            bindings: {
              handleEnter: {
                key: "Enter",
                handler: function (range, context) {
                  // 自定义Enter键的行为
                  const focusLine = self.quill.getLine(range.index);
                  if (focusLine[0].domNode.nodeName !== "P") {
                    event.preventDefault();
                    self.quill.insertEmbed(
                      self.quill.getLength(),
                      "block",
                      true,
                      "api"
                    );
                    self.quill.setSelection(self.quill.getLength() - 1);
                  }
                  return true; // 非<p>标签，执行默认行为
                },
              },
            },
          },
        },
        theme: "snow",
      });
      this.setContent();
      this.quill.format("font", "思源黑体");
      // this.quill.format("lineHeight", "2");
      this.quill.root.addEventListener("paste", this.pasteFile, true);
      this.wrapShow = true;
      const self = this;
      this.quill.container.addEventListener("click", function (e) {
        const content = self.quill.getContents();
        if (
          e.target.attributes.class &&
          e.target.attributes.class.value === "ql-editor" &&
          content.ops[content.ops.length - 1] &&
          content.ops[content.ops.length - 1].insert === "\n" &&
          (!content.ops[content.ops.length - 2] ||
            (content.ops[content.ops.length - 2] &&
              content.ops[content.ops.length - 2].insert !== "\n"))
        ) {
          self.quill.insertText(self.quill.getLength() - 1, "\n");
          self.quill.setSelection(self.quill.getLength() - 1);
        }
      });
    },
    //初始化富文本内容
    setContent() {
      this.content
        ? this.quill.setContents(JSON.parse(this.content), "api")
        : "";
      this.$nextTick(() => {
        this.quill.setSelection(null);
      });
    },
    undo() {
      this.quill.history.undo();
    },
    menuClick(index) {
      switch (index) {
        case 0:
          if (this.lineId) {
            const link = window.location.href.split("?")[0];
            const query = `?elementId=${this.lineId}`;
            let text1 = this.$refs["text_area"];
            text1.innerText = link + query;
            text1.select();
            document.execCommand("copy");
            const menu = document.getElementById("menu");
            menu.style.display = "none";
            this.$message({
              type: "success",
              message: this.$t("knowledge.copied"),
            });
          }
          break;
      }
    },
    redo() {
      this.quill.history.redo();
    },
    format() {
      if (JSON.stringify(this.formatObject) !== "{}") {
        for (let item in this.formatObject) {
          this.quill.format(item, this.formatObject[item]);
        }
        this.formatObject = {};
      } else {
        this.formatObject = this.quill.getFormat();
      }
    },
    saveContent() {
      const self = this;
      if (self.timer) {
        clearTimeout(self.timer);
      }
      self.timer = setTimeout(() => {
        const content = JSON.stringify(self.quill.getContents().ops);
        const params = {
          pathId: this.$route.params.pathId,
          query: {
            token: this.internetToken,
          },
          data: {
            content: content,
          },
        };
        editDocumentUnlogin(params).then((res) => {
          self.timer = null;
          self.$emit("contentChange", content);
        });
      }, 500);
    },
    // 监听富文本的变化
    text_change(delta, oldDelta, source) {
      this.$emit("sendingFn", true);
      // 监听改行是否设置为标题，是则禁止改变字体大小
      if (this.quill.getFormat().valueOf("header")) {
        let temp = this.quill.getFormat();
        const toolbar = this.quill.getModule("toolbar");
        if (temp.header) {
          toolbar.container.querySelector(".ql-size").style.cursor =
            "not-allowed";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "none";
        } else {
          toolbar.container.querySelector(".ql-size").style.cursor = "auto";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "auto";
        }
      }
      // 实时改变工具栏中字体大小显示
      if (
        this.quill.getLeaf(this.quill.getSelection().index)[0].domNode
          .parentNode
      ) {
        const nowNodeParent = this.quill.getLeaf(
          this.quill.getSelection().index
        )[0].domNode.parentNode;
        const size = window.getComputedStyle(nowNodeParent).fontSize;
        document
          .getElementsByClassName("ql-picker-label")[1]
          .setAttribute("data-label", size);
      }
      const selection = this.quill.getSelection();
      this.userCursorList.forEach((item) => {
        if (selection && item.index === selection.index) {
          this.$set(item, "show", false);
        } else if (selection && item.index !== selection.index) {
          this.$set(item, "show", true);
        }
      });
      const ele = document.getElementById("editor-container-wrap");
      if (ele.scrollTop !== this.wrapScrollTop) {
        ele.scrollTop = this.wrapScrollTop;
      }
      // 改版后滚动条不随之滚动修复
      if (
        this.quill.getSelection() &&
        this.quill.getLength() - 2 == this.quill.getSelection().index &&
        delta.ops[delta.ops.length - 1].insert === "\n"
      ) {
        ele.scrollTop = ele.scrollHeight;
      }
      const self = this;
      self.opsList.push(delta.ops);
      const ops = JSON.stringify(self.opsList);
      const content = JSON.stringify(self.quill.getContents().ops);
      if (self.saveTimer) {
        clearTimeout(self.saveTimer);
        self.saveTimer = null;
      }
      self.saveTimer = setTimeout(() => {
        self.stompClient.send(
          `/app/PUBLIC_EDIT_DOC_CONTENT/${this.$route.params.pathId}`,
          {},
          JSON.stringify({
            token: this.internetToken,
            ops: ops,
            content: content,
            accountId: this.userAccountId,
            selection: JSON.stringify(selection),
          })
        );
        self.saveTimer = null;
        self.opsList = [];
        self.$emit("sendingFn", false);
      }, 500);
    },
    selection_change(range, oldRange, source) {
      const toolbar = this.quill.getModule("toolbar");
      if (range) {
        const map = [
          "",
          this.$t("knowledge.Heading1"),
          this.$t("knowledge.Heading2"),
          this.$t("knowledge.Heading3"),
          this.$t("knowledge.Heading4"),
          this.$t("knowledge.Heading5"),
          this.$t("knowledge.Heading6"),
        ];
        toolbar.container
          ? toolbar.container
              .querySelector(".ql-header .ql-picker-label")
              .classList.add("ql-active")
          : "";
        const formats = this.quill.getFormat(range.index, range.length);
        const headerLevel =
          formats.header && formats.header.header ? formats.header.header : "";
        if (headerLevel) {
          toolbar.container.querySelector(".ql-size").style.cursor =
            "not-allowed";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "none";
          toolbar.container.querySelector(".ql-header .ql-picker-label")
            ? (toolbar.container.querySelector(
                ".ql-header .ql-picker-label"
              ).attributes["data-label"].value = map[headerLevel])
            : "";
        } else {
          toolbar.container.querySelector(".ql-size").style.cursor = "auto";
          toolbar.container.querySelector(
            ".ql-size .ql-picker-label"
          ).style.pointerEvents = "auto";
          toolbar.container.querySelector(".ql-header .ql-picker-label")
            ? (toolbar.container.querySelector(
                ".ql-header .ql-picker-label"
              ).attributes["data-label"].value = this.$t("knowledge.body"))
            : "";
        }
        // 点击某一行时改变工具栏中字体大小显示
        if (this.quill.getLeaf(range.index)[0].domNode.parentNode) {
          const size = window.getComputedStyle(
            this.quill.getLeaf(range.index)[0].domNode.parentNode
          ).fontSize;
          document
            .getElementsByClassName("ql-picker-label")[1]
            .setAttribute("data-label", size);
        }
        // 选中表格时候限制所有块级元素
        const disableList = [
          "ql-highlight",
          "ql-header",
          "ql-code-block",
          "ql-list1",
          "ql-list2",
          "ql-video",
          "ql-better-table",
          "ql-drawio",
        ];
        if (formats["table-cell-line"]) {
          disableList.forEach((item) => {
            document.getElementById(item).classList.add("disable-ql-option");
          });
        } else {
          disableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
        }
        const linkDisableList = ["ql-drawio"];
        if (formats["link"]) {
          linkDisableList.forEach((item) => {
            document.getElementById(item).classList.add("disable-ql-option");
          });
        } else {
          linkDisableList.forEach((item) => {
            document.getElementById(item)
              ? document
                  .getElementById(item)
                  .classList.remove("disable-ql-option")
              : "";
          });
        }
      } else {
        toolbar.container.querySelector(
          ".ql-header .ql-picker-label"
        ).attributes["data-label"].value = this.$t("knowledge.body");
      }
    },
    goTop() {
      const doc = document.getElementById("no-hidden-dialog");
      if (doc) {
        doc.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.edit-wrap {
  width: 85%;
  height: 100%;
  position: relative;
  text-align: left;
  padding: 10px;
  flex-grow: 1;
  .at_position {
    position: absolute;
  }
  /*no*/
  & > div {
    height: 100%;
  }
  .ql-toolbar {
    /*no*/
    border: none;
  }
  .ql-container {
    height: calc(100% - 40px);
    /*no*/
    border: none;
    // overflow-y: scroll;
  }
  .ql-formats {
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      right: -10px;
      background-color: rgba(204, 204, 204, 1);
    }
  }
  .cursor_span {
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 30px;
    left: 0;
    top: 0;
    & > i {
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: 0;
      left: -3px;
      border-radius: 3px;
      cursor: pointer;
    }
    & > span {
      position: absolute;
      display: inline-block;
      background-color: rgba(225, 225, 225, 1);
      top: -20px;
      left: 4px;
      font-size: 16px;
      width: max-content;
      padding: 4px;
      border-radius: 4px;
      height: max-content;
      line-height: 20px;
    }
  }
  .twinkle {
    animation: twinkle 1s infinite;
  }
  @keyframes twinkle {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.goTop {
  position: absolute;
  bottom: -90%;
  right: 50px;
  border-radius: 50%;
  background-color: white;
  i {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: rgba(145, 147, 153, 1);
  }
}
</style>
<style lang="scss">
.ql-editor {
  height: fit-content;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
}
.ql-font {
  margin-right: 10px;
}
.ql-font,
.ql-size {
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 2px;
}
.ql-editing {
  left: 20px !important;
}
.ql-video {
  width: 1027px;
  height: 624px;
}
#menu {
  display: none;
  position: absolute;
  width: 130px;
  height: fit-content;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;

  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;

      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss" scoped></style>

<template>
  <div v-if="!isCollapse" class="catalogue-wrap">
    <p 
      style="
        text-align: left; 
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-left: 30px;
        color: rgba(96, 98, 102, 1);
        " 
      @click="collpaseAside"
    >
      {{ $t("knowledge.ArticleOutline") }}
      <i
        class="iconfont icon-shouqi1"
        style="
          width: 24px;
          height: 24px;
          margin-right: 12px;
          color: rgba(128, 128, 128, 1);
        "
      ></i>
    </p>
    <!-- <p>{{ $t("knowledge.ArticleOutline") }}</p> -->
    <div class="tree-wrap">
      <el-tree
        ref="tree"
        :data="data"
        node-key="id"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        @node-click="nodeClick"
      >
      </el-tree>
    </div>
  </div>
  <div v-else class="left-menu-hide" @click="collpaseAside">
    <i
      class="iconfont icon-zhedie1"
      style="display: inline-block;color: rgb(145, 147, 153);font-size: 20px;font-weight: 600;"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      isCollapse: false,
      currentData: [],
      lastcheckid: "",
      isClick: false,
    };
  },
  watch: {
    content(newVal) {
      this.getContent();
    },
    editable(newVal) {
      let scrollingContainer = document.getElementById("no-hidden-dialog");
      if (!scrollingContainer) {
        scrollingContainer = document.getElementById("editor-container");
      }
      if (scrollingContainer) {
        scrollingContainer.addEventListener("scroll", this.checkActiveTitle);
      }
    },
  },
  mounted() {
    this.updateTree();
    let scrollingContainer = document.getElementById("no-hidden-dialog");
    if (!scrollingContainer) {
      scrollingContainer = document.getElementById("editor-container");
    }
    if (scrollingContainer) {
      scrollingContainer.addEventListener("scroll", this.checkActiveTitle);
    }
    let editorContainerWrap = document.getElementById("editor-container-wrap");
    if(editorContainerWrap) {
      editorContainerWrap.addEventListener("scroll", this.checkActiveTitle);
    }
    const titleId = this.$route.query.elementId;
    if (titleId) {
      this.activateTitleById(titleId);
    }
  },
  beforeDestroy() {
    let scrollingContainer = document.getElementById("no-hidden-dialog");
    if (!scrollingContainer) {
      scrollingContainer = document.getElementById("editor-container");
    }
    if (scrollingContainer) {
      scrollingContainer.removeEventListener("scroll", this.checkActiveTitle);
    }
    let editorContainerWrap = document.getElementById("editor-container-wrap");
    if(editorContainerWrap) {
      editorContainerWrap.removeEventListener("scroll", this.checkActiveTitle);
    }
  },
  updated() {
    this.$nextTick(() => {
      let scrollingContainer = document.getElementById("no-hidden-dialog");
      if (!scrollingContainer) {
        scrollingContainer = document.getElementById("editor-container");
      }
      if (scrollingContainer) {
        scrollingContainer.addEventListener("scroll", this.checkActiveTitle);
      }
      let editorContainerWrap = document.getElementById("editor-container-wrap");
      if(editorContainerWrap) {
        editorContainerWrap.addEventListener("scroll", this.checkActiveTitle);
      }
      const titleId = this.$route.query.elementId;
      if (titleId) {
        this.activateTitleById(titleId);
      }
    });
  },
  methods: {
    //去除循环结构
    // deletParent(list) {
    //   list.forEach((item) => {
    //     delete item.parent;
    //     if (item.children) {
    //       this.deletParent(item.children);
    //     }
    //   });
    // },
    // 构造树结构
    // buildTree(list) {
    //   const root = { children: [] };
    //   let current = root;
    //   list.forEach((item) => {
    //     const obj = {
    //       label: item.name,
    //       type: item.type,
    //       children: [],
    //       parent: undefined,
    //     };
    //     while (current !== root && current.type.header - obj.type.header > -1) {
    //       current = current.parent;
    //     }
    //     obj.parent = current;
    //     obj.parent.children.push(obj);
    //     current = obj;
    //   });
    //   this.deletParent(root.children);
    //   this.data = root.children;
    //   this.$emit("catalogueUpdate", root.children);
    // },
    // 过滤H标签
    // filterTree(delta) {
    //   const list = [];
    //   delta.forEach((item, index) => {
    //     if (
    //       item.insert === "\n" &&
    //       index >= 1 &&
    //       delta[index - 1] &&
    //       !delta[index - 1].insert.image &&
    //       item.attributes &&
    //       item.attributes.header
    //     ) {
    //       const splitArr = delta[index - 1].insert.split("\n");
    //       const obj = {
    //         name: splitArr[splitArr.length - 1],
    //         type: item.attributes.header,
    //       };
    //       list.push(obj);
    //     }
    //   });
    //   this.currentData = list;
    //   this.buildTree(list);
    // },
    updateTree() {
      this.getContent();
    },
    collpaseAside() {
      this.isCollapse = !this.isCollapse;
    },
    nodeClick(node) {
      this.lastcheckid && document.getElementById(this.lastcheckid)
        ? document
            .getElementById(this.lastcheckid)
            .classList.remove("checked_catalogue")
        : "";
      document.getElementById(node.type.id).classList.add("checked_catalogue");
      const rollDom = document.getElementById(node.type.id);
      const warp =
        document.getElementById("no-hidden-dialog") ||
        document.getElementById("editor-container-wrap");
        this.isClick = true;
      warp.scrollTop = rollDom.offsetTop;
      this.lastcheckid = node.type.id;
    },
    // 获取数据
    getContent() {
      if (this.content) {
        const delta = JSON.parse(this.content);
        // this.filterTree(delta);
        // 创建Worker
        const worker = new Worker("/js/worker/knowledgeLeftMenuTree.js");
        // 发送消息
        worker.postMessage(delta);
        const self = this;
        worker.addEventListener("message", function (e) {
          const tree = e.data.tree;
          self.data = tree;
          self.assignIds(self.data);
          self.$emit("catalogueUpdate", tree);
        });
      } else {
        this.data = [];
      }
    },
    selectTitle(node) {
      this.$refs.tree.setCurrentNode(node);
      this.$nextTick(()=>{
        const currentNode = document.getElementsByClassName('is-current')[1] || document.getElementsByClassName('is-current')[0] 
        const currentNodeParent = document.getElementsByClassName('tree-wrap')[0]
        this.scrollToView(currentNodeParent,currentNode);
      })
    },
    scrollToView(parent, child) {
        const parentRect = parent.getBoundingClientRect();
        const childRect = child.getBoundingClientRect();
        const childTopPosition = childRect.top - parentRect.top;
        const childBottomPosition = childRect.bottom - parentRect.top;
        const parentCenter = parentRect.height / 2;
        if(childTopPosition>parentRect.height){
          parent.scrollTop += childBottomPosition - parentRect.height;
        }else if (childTopPosition < parentCenter) {
            parent.scrollTop += childTopPosition;
        }
    },
    recursiveFindNode(flatNodes) {
      let scrollingContainer = document.getElementById("no-hidden-dialog")||document.getElementById("editor-container-wrap");
      if (!scrollingContainer) {
        scrollingContainer = document.getElementById("editor-container");
        if (!scrollingContainer && !this.editable) {
          scrollingContainer =
            scrollingContainer.getElementsByClassName("ql-editor")[0];
        }
      }

      let scrollPosition = scrollingContainer.scrollTop;
      for (let i = 0; i < flatNodes.length; i++) {
        let node = flatNodes[i];
        let nodeDom = document.getElementById(node.type.id);
        let nodePosition = nodeDom.offsetTop;

        if (nodePosition > scrollPosition) {
          if(this.isClick){
            this.isClick = false
            return flatNodes[i];
          }else{
            return i>0?flatNodes[i-1]:flatNodes[i];
          }
        }
      }
      return flatNodes[flatNodes.length - 1];
    },

    checkActiveTitle($e) {
      const goTop = document.getElementsByClassName('goTop')[0];
      if($e.target){
        if($e.target.scrollTop!=0){
          goTop.style.display = 'block'
        }else{
          goTop.style.display = 'none'
        }
      }
      const flatNodes = this.flattenNodes(this.data);
      const activeNode = this.recursiveFindNode(flatNodes);
      if (activeNode) {
        this.selectTitle(activeNode);
      }
    },

    flattenNodes(data, result = []) {
      for (let node of data) {
        result.push(node);
        if (node.children.length > 0) {
          this.flattenNodes(node.children, result);
        }
      }
      return result;
    },

    assignIds(data) {
      data.forEach((node) => {
        node.id = node.type.id;
        if (node.children) {
          this.assignIds(node.children);
        }
      });
    },
    activateTitleById(id) {
      const flatNodes = this.flattenNodes(this.data);
      const nodeToActivate = flatNodes.find((node) => node.type.id == id);
      if (nodeToActivate) {
        this.selectTitle(nodeToActivate);
      } else {
        const activeNode = this.recursiveFindNode(flatNodes);
        if (activeNode) {
          this.selectTitle(activeNode);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.catalogue-wrap {
  width: 15%;
  height: 92%;
  // border-right: 1px solid rgba(166, 166, 166, 1);
  display: flex;
  flex-direction: column;
  .tree-wrap {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #ebeef5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(207, 207, 207, 1);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }
  & > p {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  ::v-deep {
    .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      color: rgba(42, 130, 228, 1);
      background-color: #f0f7ff !important;
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: transparent;
    }
    .el-tree-node__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.left-menu-hide {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  text-align: center;
  &:hover {
    background-color: #f0f7ff;
    cursor: pointer;
  }
}
.left-menu-hide{
    position: relative;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding-right: 17px;
    padding-left: 18px;
    border-radius: 0 30px 30px 0;
    background-color: white;
    border: 1px solid rgba(229, 229, 229, 1);
    border-left: none;
}
</style>
<style>
.checked_catalogue {
  background-color: #f0f7ff;
}
</style>
